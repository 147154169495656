/* @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: "Roboto", sans-serif !important;
}

.engine-selector {
  font-size: 13px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px 10px !important;
}

.less-p button {
  padding: 5px;
  margin-right: 5px;
}

/* a {
  text-decoration: none;
  color: #000;
}

p {
  line-height: 1.7;
}

ul {
  list-style: none;
}

li {
  line-height: 2.2;
}

h1,
h2,
h3 {
  font-weight: 600;
  margin-bottom: 10px;
}
.nav-link{
  transition: none !important;
}
.container {
  width: 100%;
  max-width: 960px;
  margin: 0 auto;
  padding: 0 20px;
  text-align: center;
} */

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 10px;
  border-bottom: 1px solid #e6e6e6;
  margin-bottom: 10px;
  position: sticky;
  top: 0;
  background: #fff;
  z-index: 2;
  /* box-shadow: 0 0 6px #ccc; */
}

.header .link-unstyled a {
  text-decoration: none;
  color: black;
}


.cluster-button {
  font-size: 12px !important;
  padding: 5px 5px !important;
}

.header ul {
  margin-bottom: 0;
}

.filter-keywords {
  box-shadow: 0 0 6px #ccc;
}

.link-unstyled {
  text-decoration: none;
  color: black;
}

.btn.btn-success.generate,
.btn.btn-success.export {
  background-color: #7EE4BB;
  border-radius: 1px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 13px;
  border: none;
  height: 25px;
}

.btn.btn-success.generate.processing {
  background-color: #e4817e;
}

.file-input {
  width: -webkit-fill-available;
  font-size: 13px;
  border-radius: 0 !important;
  color: #3a3a3a !important;
  border: 2px solid #e5e5e5;
}


.rdt_TableHead .rdt_TableHeadRow .rdt_TableCol .form-check input {
  width: 27px !important;
  height: 27px !important;
  cursor: pointer;
}

.rdt_TableBody .rdt_TableRow .rdt_TableCell .form-check input {
  width: 18px !important;
  height: 18px !important;
}

.rdt_TableBody .rdt_TableRow {
  min-height: 30px;
}

/* div:has(.rdt_Pagination) {
  position: sticky !important;
  bottom: 0 !important;
} */

.filter-header .form-control,
.form-select {
  font-size: 14px;
}

/* Decrease the font size of the notification */
.Toastify__toast-body {
  font-size: 10px;
  /* Adjust the font size as needed */
}

/* Decrease the padding, margins, and width to make the notification smaller */
.Toastify__toast {
  padding: 3px 0px;
  margin: 6px 0;
  width: 230px;
  /* Adjust the width as needed */
  display: flex;
  /* Use flexbox */
  justify-content: flex-start;
  /* Align to the left */
  align-items: center;
  /* Align vertically */
  position: relative;
  /* Use relative positioning */
  min-height: 20px !important;
}

/* Override the animation properties for Toastify toast animation */
.Toastify__progress-bar {
  visibility: hidden !important;
}
.keywords-table.group-table .rdt_TableHead .rdt_TableHeadRow div:nth-child(3),
.keywords-table.group-table .rdt_TableHead .rdt_TableHeadRow div:nth-child(4),
.keywords-table .rdt_TableHead .rdt_TableHeadRow div:nth-child(5),
.keywords-table .rdt_TableHead .rdt_TableHeadRow div:nth-child(6),
.keywords-table .rdt_TableHead .rdt_TableHeadRow div:nth-child(7),
.keywords-table .rdt_TableHead .rdt_TableHeadRow div:nth-child(8),
.keywords-table .rdt_TableHead .rdt_TableHeadRow div:nth-child(9) {
  padding: 0 !important;
}



.Toastify__toast-container {
  position: fixed;
  /* Use fixed positioning for the container */
  top: auto !important;
  bottom: 0 !important;
  left: 0 !important;
  /* Position the container all the way to the right */
  right: auto;
  width: 100%;
  /* Set full width */
  display: flex;
  /* Use flexbox to align notifications */
  flex-direction: column;
  /* Align notifications vertically */
  align-items: flex-start;
  /* Align notifications to the right */
}
.actions-container {
  position: relative; /* Position relative for absolute positioning of actions-list */
}

.actions-list {
  cursor: pointer;
  position: absolute;
  top: 100%; /* Position below the toggle button */
  right: -100px; /* Align to the right */
  width: 350px; /* Increase the width further */
  max-height: 150px; /* Set maximum height for scrollability */
  font-size: 12px;
  background-color: white; /* Set background color */
  border: 1px solid #ccc; /* Add border for better visibility */
  padding: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Add shadow for better visibility */
  z-index: 2; /* Set z-index to ensure it appears on top */
  overflow-y: auto; /* Enable vertical scrolling */
  display: none; /* Hide by default */
}
.actions-icon {
  margin-right: 3px;
}

.actions-container.show .actions-list {
  display: block; /* Show when .show class is applied */
}

.toggle-actions-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 10px; /* Add padding for better button appearance */
  display: flex; /* Make the button and icon align horizontally */
  align-items: center; /* Center the icon vertically */
  font-size: 14px;
  border-radius: 5px; /* Add border radius for rounded corners */
  position: relative; /* Position relative for absolute positioning of actions-list */
}

.toggle-actions-button:hover {
  background-color: #f0f0f0; /* Change background color on hover */
}

.undo-button {
  background-color: transparent;
  border: 1px solid #ccc; /* Add border for better visibility */
  padding: 5px 5px; /* Add padding */
  cursor: pointer;
  border-radius: 5px; /* Add border radius for rounded corners */
}

.undo-button:hover {
  background-color: #f0f0f0; /* Change background color on hover */
}

.action-type {
  font-weight: bold; /* Make the title bold */
}

.action-item-border {
  border-bottom: 1px solid #ccc; /* Add border bottom */
  padding: 5px 0px;
}
.no-actions-message {
  text-align: center;
}


div.filter-header {
  position: -webkit-sticky;
  position: sticky;
  top: 0px;
  z-index: 4;
  background: #fff;
}

.rdt_TableBody .rdt_TableRow .rdt_TableCell .form-check input {
  width: 27px !important;
  height: 27px !important;
  cursor: pointer;
}

.custom-switch {
  transform: scale(0.7); /* Adjust scale as needed */
}

.project {
  background-color: whitesmoke;
  cursor: pointer;
  text-decoration-style: red;
  padding: 38px;
}

.project:hover .link-unstyled {
  color: whitesmoke;
}

.project:hover {
  background-color: rgb(83, 82, 82);
}

a {
  text-decoration: none !important;
  color: rgb(83, 82, 82);
}

.project:hover .message-status {
  color: whitesmoke !important;
}

.header ul {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header ul li {
  margin-left: 20px;
}

.header ul li a {
  display: flex;
  align-items: center;
}

.header ul li a:hover {
  color: #777;
}

.header ul li a svg {
  margin-right: 5px;
}

.heading {
  font-size: 2rem;
  font-weight: 700;
  margin-bottom: 50px;
  padding: 0 20px;
}

.heading p {
  color: #828282;
}

.goals {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
}

/* .goal {
  background-color: #f4f4f4;
  margin: 10px 0;
  padding: 20px 0 10px;
  position: relative;
}

.goal .close {
  position: absolute;
  top: 10px;
  right: 15px;
  cursor: pointer;
  border: none;
  background: none;
}

.form,
.content {
  width: 70%;
  margin: 0 auto;
}

.form-group {
  margin-bottom: 10px;
} */

.form-group input,
.form-group textarea,
.form-group select {
  width: 100%;
  padding: 10px;
  border: 1px solid #e6e6e6;
  border-radius: 5px;
  margin-bottom: 10px;
  font-family: inherit;
}

.form-group label {
  text-align: left;
  display: block;
  margin: 0 0 5px 3px;
}

.btn {
  padding: 10px 20px;
  border: 1px solid #000;
  border-radius: 5px;
  background: #000;
  color: #fff;
  font-size: 13px;
  font-weight: 700;
  cursor: pointer;
  text-align: center;
  appearance: button;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn svg {
  margin-right: 8px;
}

.btn-reverse {
  background: #fff;
  color: #000;
}

.btn-block {
  width: 100%;
  margin-bottom: 20px;
}

/* .btn:hover {
  transform: scale(1);
} */

.loadingSpinnerContainer {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 5000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loadingSpinner {
  width: 64px;
  height: 64px;
  border: 8px solid;
  border-color: #000 transparent #555 transparent;
  border-radius: 50%;
  animation: spin 1.2s linear infinite;
}


.buttonIn {
  width: 300px;
  position: relative;
}

.project-screen {
  padding-top: 5px;
}

.project-screen input {
  width: 30%;
  font-size: 13px;
  margin-right: 10px;
}

.project-screen button {
  font-size: 13px;
  background-color: #3A8374;
  color: white;
}

.form-control:focus {
  box-shadow: none !important;
}

.upload-sec {
  align-items: center !important;
  justify-content: space-between;
}

.upload-sec .input-group-append .input-group-text {
  background-color: #212529;
  border: 1px solid #212529;
  outline: none;
  color: #fff;
  border-radius: 5px;
}

.upload-sec .input-group-append button {
  border: none;
}

.upload-modal .modal-dialog {
  width: 650px;
}

.upload-modal .modal-dialog .modal-body {
  width: 100% !important;
}

.text-grey {
  color: #828282;
}

.keywords-table {
  color: #3a3a3a !important;
  font-family: "Roboto", sans-serif;
  overflow-y: auto !important;
  height: 63vh;
    /* overflow-x: hidden !important; */
  /* box-shadow: 0 0 5px #ccc; */
}

.projects-table {
  color: #3a3a3a !important;
  font-family: "Roboto", sans-serif;
  overflow-y: auto !important;
  overflow-x: hidden;
  width: 100% !important;
  height: 70vh;
}
.logsContainer{
  flex: 1;
  display: flex !important;
  background-color: #0d6efd;
}

.logs-table {
  color: #3a3a3a !important;
  font-family: "Roboto", sans-serif;
  overflow-y: auto !important;
  overflow-x: hidden;
  width: 100% !important;
  /* overflow-x: hidden !important;z */
  /* box-shadow: 0 0 5px #ccc; */
}

.gh2-table .rdt_TableBody .rdt_TableRow .rdt_TableCell .form-check input {
  width: 18px !important;
  height: 18px !important;
  font-size: 11px !important;
}

.main-check input {
  width: 18px !important;
  height: 18px !important;
  font-size: 11px !important;
}

.gh2-table .dApqnJ {
  height: 100% !important;
}

.gh2-table .rdt_Table {
  border: 1px solid #d0d0d1;
  position: relative;
}

.gh2-table .rdt_Table .rdt_TableHead .rdt_TableHeadRow {
  background-color: #d0d0d1;
  min-height: 27px;
  position: sticky;
}
.logs-modal {
  display: flex;
  align-items: center;
  justify-content: center;
}

.logs-modal .modal-dialog {
  max-width: 650px; /* Adjust the max-width as needed */
}

.logs-modal .modal-content {
  width: 100%;
}


.projects-table .rdt_TableBody .rdt_TableRow .rdt_TableCell .form-check input {
  width: 18px !important;
  height: 18px !important;
  font-size: 11px !important;
}

.logs-table .rdt_TableBody .rdt_TableRow .rdt_TableCell .form-check input {
  width: 18px !important;
  height: 18px !important;
  font-size: 11px !important;
}

.main-check input {
  width: 18px !important;
  height: 18px !important;
  font-size: 11px !important;
}

.projects-table .dApqnJ {
  height: 100% !important;
}

.projects-table .rdt_Table {
  border: 1px solid #d0d0d1;
  position: relative;
}

.user-management-table  {
  border: 1px solid #d0d0d1;
  position: relative;
}

.projects-table .rdt_Table .rdt_TableHead .rdt_TableHeadRow {
  background-color: #d0d0d1;
  min-height: 27px;
  position: sticky;
}

#user-management-search {
  border: 1px solid #ccc; /* Add a light border */
  border-radius: 5px; /* Add a light border radius */
  padding: 5px 10px; /* Add some padding */
  margin-right: 10px; /* Add some spacing */
}

.logs-table .dApqnJ {
  height: 100% !important;
}

.logs-table .rdt_Table {
  border: 1px solid #d0d0d1;
  position: relative;
}

.logs-table .rdt_Table .rdt_TableHead .rdt_TableHeadRow {
  background-color: #d0d0d1;
  min-height: 27px;
  position: sticky;
}


.keywords-table.ngram {
  font-family: "Roboto", sans-serif;
  /* overflow-x: hidden; */
  font-size: 11px !important;
  z-index: 2;
}

.keywords-table .rdt_TableBody .rdt_TableRow .rdt_TableCell .form-check input {
  width: 18px !important;
  height: 18px !important;
  font-size: 11px !important;
}

/* .keywords-table.ngrams-table {
  height: 40vh;
  overflow-y: auto !important;
} */
/* .keywords-table .dApqnJ {
  height: 100% !important;
} */

.keywords-table .rdt_Table {
  border: 1px solid #d0d0d1;
  position: relative;
}

.user-management-table .rdt_Table .rdt_TableHead .rdt_TableHeadRow {
  background-color: #d0d0d1;
  min-height: 27px;
}
.user-management-table{
  color: #3a3a3a !important;
  font-family: "Roboto", sans-serif;
  overflow-y: auto !important;
  height: 63vh;
}

.keywords-table .rdt_Table .rdt_TableHead .rdt_TableHeadRow {
  background-color: #d0d0d1;
  min-height: 27px;
}

.keywords-table #cell-2-undefined div:first-child {
  white-space: initial;
  overflow: visible;
}

.user-management-table.logs #cell-3-undefined div:first-child {
  white-space: initial;
  overflow: visible;
}

.logs-select {
  font-size: 12px;
  max-height: 25px;
  z-index: 4;
}

.logs-select >:nth-child(3) {
  min-height: 33px;
  height: 25px;
  flex-wrap: nowrap;
  border-color: #e5e5e5;
  border-radius: 0 !important;
}

.keywords-table.ungrouped .rdt_Table .rdt_TableHead .rdt_TableHeadRow .glyQNg {
  padding: 0;
}

.keywords-table .rdt_Table .rdt_TableBody {
  width: 100%;
  position: relative;
}

.keywords-table.ungrouped .rdt_Table .rdt_TableBody .rdt_TableRow .eVPPvF {
  padding: 0;
}

.filter-section button {
  font-size: 10px;
  height: 25px;
  padding: 0 5px;
  background-color: #26282b;
  border: 1px solid #212529;
  color: #3a3a3a !important;
  white-space: nowrap;
  margin: 0 4px 0 2px;
}
.filter-section .group-btn {
  font-size: 13px;
  height: 30px !important;
  width: 50px !important;
}
.filter-section button:hover {
  background-color: #26282b;
  border: #26282b;
  color: #fff;
}

.filter-section {
  position: relative;
}

.goaKXo {
  padding: 0;
}

.nav-fill .nav-item .nav-link,
.nav-justified .nav-item .nav-link {
  width: auto !important;
}

.nav-fill .nav-item,
.nav-fill>.nav-link {
  flex: 0 !important;
}

.nav-pills .nav-link.active,
.nav-pills .nav-link {
  text-align: left;
  border: 2px solid black;
  color: #3a3a3a !important;
  padding: 5px;
  margin: 2px;
}

.nav-pills .nav-link.active,
.nav-pills .nav-link:hover {
  background-color: black !important;
  color: white;
}

/* .scroable-div{
  height: 72vh;
  overflow: auto;
}
.scroable-div.tokens{
  max-height: 400px;
  overflow: auto;
} */
.left-panel.tokens {
  max-height: 600px;
  overflow: auto;
}

.no-radius.form-control,
.no-radius.form-select {
  font-size: 13px;
  border-radius: 0 !important;
  color: #3a3a3a !important;
  border: 1px solid #e5e5e5;
}

.projects-details-tab .nav-item .nav-link {
  border-radius: 0;
  border: 1px solid #dee2e6;
  color: #8e9193;
  /* font-size: 13px; */
  height: 25px;
  padding: 0px 10px;
  display: flex;
  align-items: center;
}
.projects-ngram-text .nav-item .nav-link{
  font-size: 11px !important;
}
.projects-kwgroup-text .nav-item .nav-link{
  font-size: 13px !important;
}

.projects-details-tab .nav-item .nav-link.active {
  border-bottom: 1px solid #dee2e6;
  border: 1px solid #e5e5e5;
}

.projects-details-tab .nav-item:nth-child(1) {
  background-color: #fdf6e5;
  border-color: #fdf6e5;

}

.projects-details-tab .nav-item:nth-child(2) {
  background-color: #e7f2e6;
  border-color: #e7f2e6;
}

.projects-details-tab .nav-item:nth-child(3) {
  background-color: #fae6e4;
  border-color: #fae6e4;
}

.projects-details-tab.blocked-unblocked-tab .nav-item:nth-child(1) {
  background-color: #fdf6e5;
  border-color: #fdf6e5;
}

.projects-details-tab.blocked-unblocked-tab .nav-item:nth-child(2) {
  background-color: #fae6e4;
  border-color: #fae6e4;
}

.projects-details-tab .nav-item {
  margin-right: 3px;
}

.projects-details-tab.nav-tabs {
  border-bottom: 0;
}

.w-90 {
  width: 90px;
}

.text-left {
  text-align: left;
  color: #2a2a2a !important;
  font-size: 13px;
}
.text-right {
  text-align: left;
  color: #2a2a2a !important;
  font-size: 11px;
}

.light-mustard {
  background-color: #FFF3CD;
}

.light-green {
  background-color: lightgreen;
}

.form-control {
  font-size: 13px;
}

.no-radius {
  border-radius: 0 !important;
}

.height-50 {
  height: 25px;
}

.rdt_Pagination {
  font-size: 11px !important;
}

.search-pagename {
  width: 250px !important;
  font-size: 13px;
}

.tabs-section {
  position: relative;
}

.green-bg {
  background-color: #e7f2e6 !important;
  border: 1px solid #f4f4f4 !important;
  color: #212529 !important;
  font-size: 13px;
}

.bg-red {
  background-color: #fae6e4 !important;
  border: 1px solid #fae6e4 !important;
  color: #212529 !important;
}

.bg-yellow {
  background-color: #fdf6e5 !important;
  border: 1px solid #fdf6e5 !important;
  color: #212529 !important;
}

.keywords-table.ngram .rdt_TableRow div:nth-child(1) {
  min-width: 33px;
  flex: 0 0 0;
}

.keywords-table.ngram .rdt_TableRow div:nth-child(2) {
  min-width: 23px;
  flex: 0 0 0;
}

.keywords-table.ngram .rdt_TableHead div:nth-child(2) {
  display: none;
}

.tabs-section .search-page-sec .form-control {
  height: 25px;
  font-size: 13px;
  border: 1px solid #e5e5e5 !important;
  color: #3a3a3a;
}

.table-checkbox:hover {
  cursor: pointer;
}

.table-checkbox .form-check-input {
  width: 20px;
  height: 20px;
  cursor: pointer;

}


.color-blue {
  color: #0d6efd !important;
}
.rotate {
  animation: rotateAnimation 0.5s; /* Adjust the duration as needed */
}

@keyframes rotateAnimation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.v-hidden {
  visibility: hidden;
}

/* .search-page-sec.groupTab{
  position: absolute;
  top: 0;
  left: 59%;
  transform: translateX(-50%);
  height: 50px;
  width: 61%;
  padding-left: 400px;
} */
.positionabsolute-right0 {
  position: absolute;
  right: 0px;
  top: 0;
}

.w-35 {
  width: 24% !important;
}

.pt-50 {
  padding-top: 50px;
}

.keywords-table.ungrouped .rdt_Table .rdt_TableBody #cell-1-undefined {
  min-width: 478px;
}

/* .keywords-table.ungrouped #cell-2-undefined {
  max-width: 20px !important;
} */
.keywords-table.ungrouped #cell-3-undefined {
  max-width: 6px !important;
}

.keywords-table.ungrouped #cell-4-undefined {
  max-width: 6px !important;
}

/* .keywords-table.ungrouped .rdt_TableHead .rdt_TableHeadRow div:nth-child(2) {
  min-width: 459px !important;
} */
/* .keywords-table.ungrouped .rdt_TableHead .rdt_TableHeadRow div:nth-child(3) {
  max-width: 10px !important;
} */
.keywords-table.ungrouped .rdt_TableHead .rdt_TableHeadRow div:nth-child(3) {
  max-width: auto;
  min-width: 476px !important;
}

.keywords-table.ungrouped .rdt_TableHead .rdt_TableHeadRow div:nth-child(4),
.keywords-table.ungrouped .rdt_TableHead .rdt_TableHeadRow div:nth-child(5) {
  min-width: 10px;
}

.light-green {
  background-color: #E7F2E6 !important;
}

.keywords-table.ungrouped .lmTtlo {
  flex: 0 !important;
  /* min-width: 180px !important; */
  padding: 0;
  min-width: 65px;
}

.btn-dark {
  font-size: 13px !important;
}

.pt-10 {
  padding-top: 10px;
}

.w-130 {
  max-width: 130px;
  width: 130px;
}

.keyword-screen {
  margin-top: -10px;
}

.keyword-screen a {
  color: #3a3a3a !important;
}

.no-button {
  background-color: transparent;
  border: none;
}

.no-button svg {
  margin-left: 20px;
  width: 20px;
  height: 20px;
}

.projects-grouping-tab.nav-tabs {
  align-items: center;
  justify-content: center;
  font-size: 13px;
  border-bottom: none !important;
}

.projects-grouping-tab .nav-item {
  min-width: 140px;
}

.projects-grouping-tab.nav-tabs .nav-link {
  border: none !important;
  border-top-left-radius: none !important;
  border-top-right-radius: none !important;
  color: #495057;
}

.projects-grouping-tab.nav-tabs .nav-link.active {
  border-bottom: 1px solid #e5e5e5 !important;

  color: #212529;
  border-color: none !important;
}

.filter-components {
  max-width: 330px;
  display: flow-root;
  align-items: start;
  justify-content: start;
  margin: 3px 0;
}

.filter-components h4 {
  margin-bottom: 0;
  font-size: 13px;
  font-weight: 500;
  display: inline;
  color: #5a5a5a !important;
}

.filter-components span:hover {
  background-color: #d9d9d9;
  transition: all 400ms ease;
}
.keywords-table.tab-first .rdt_TableHead div:nth-child(2),
.keywords-table.grouped-aligned .rdt_TableHead div:nth-child(2) {
  min-width: 20px !important;
  flex: 0 0 0 !important;
  padding: 0 !important;
}
 .keywords-table.tab-first .rdt_TableBody .rdt_TableRow div:nth-child(2),
 .keywords-table.grouped-aligned .rdt_TableBody .rdt_TableRow div:nth-child(2)
  {
  min-width: 23px !important;
  flex: 0 0 0 !important;
}/*
.keywords-table .rdt_TableHead .rdt_TableRow .rdt_TableCol <data-column-id="1" {
  min-width: 23px !important;
  flex: 0 0 0 !important;
} */
.filter-components span {
  cursor: pointer;
  background-color: #eaeaea;
  color: #3a3a3a;
  border-radius: 2px;
  display: inline-flex;
  align-items: center;
  font-size: 13px;
  min-width: 30px;
  height: 20px;
  margin: 0px 2px;
  padding: 8px 8px;
  margin-top: 2px;
}

.file-list {
  list-style: none;
  padding: 0;
  margin-top: 10px;
}

.file-list li {
  margin-bottom: 5px;
  font-size: 16px;
  font-size: 11px;
}

.file-list-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 5px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  transition: background-color 0.3s;
}

.file-list-item:hover {
  background-color: #f5f5f5;
}

.file-list-item .delete-icon {
  font-size: 13px;
  height: 20px;
  width: 36px;
  cursor: pointer;
  margin-left: auto;
}

.add-more-button {
  background-color: #777;
  color: #fff;
  padding: 5px 5px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 13px;
}

.add-more-button:hover {
  background-color: #999;
}

.add-more-button:active {
  background-color: #555;
}

.filter-components.main span {
  cursor: pointer;
  background-color: #eaeaea;
  color: #000;
  border-radius: 5px;
  display: inline-flex;
  align-items: center;
  font-size: 11px;
  min-width: 30px;
  height: 20px;
  margin: 0px 2px;
  padding: 8px 10px;
  margin-top: 2px;
}

.blocked-unblocked-table {
  max-height: 400px;
  overflow: auto;
}

.blocked-unblocked-table thead {
  position: sticky;
  top: 0;
}

.blocked-unblocked-table thead tr th {
  background-color: #d0d0d1;
  font-size: 11px;
  font-weight: 400;
  border: none !important;
}

.blocked-unblocked-table tbody tr td {
  font-size: 11px;
  font-weight: 400;
  border: none !important;
}

.btn-block-label {
  width: 5px;
  padding: 7px !important;
  max-width: 5px;
  max-height: 10px;
  /* color: #89D3F4; */
  display: flex !important;
  height: 10px;
  background-color: white !important;
  border-radius: 120px !important;
  border: 1px solid #68C8F1 !important;
}

.btn-block-label.bg-yellow {
  width: 5px;
  padding: 7px !important;
  max-width: 5px;
  max-height: 10px;
  display: flex !important;
  height: 10px;
  background-color: white !important;
  border-radius: 120px !important;
  border: 1px solid #ED6D7B !important;
}

.btn-block {
  width: 10px;
  padding: 10px !important;
  max-width: 10px;
  max-height: 20px;
  color: #3a3a3a;
  display: flex !important;
  height: 20px;
  background-color: #fae6e3 !important;
  border-radius: 120px !important;
  border: 1px solid #d0d0d1 !important;
}

.btn-block.bg-yellow {
  width: 10px;
  padding: 10px !important;
  max-width: 10px;
  max-height: 20px;
  display: flex !important;
  height: 20px;
  background-color: #fdf6e5 !important;
  border-radius: 120px !important;
  border: 1px solid #d0d0d1 !important;
}

.equal-width {
  min-width: 120px;
}

.left-table .rdt_Table .rdt_TableHead .rdt_TableHeadRow .rdt_TableCol {
  min-width: 55px;
  max-width: 55px;
  padding: 0 5px !important;
}

.left-table .rdt_Table .rdt_TableBody .rdt_TableCell {
  min-width: 55px;
  max-width: 55px;
  padding: 0 5px !important;
}

/* .keywords-table.grouped .rdt_Table .rdt_TableHeadRow div:nth-child(2){
  min-width: 30px;
  max-width: 80px;
} */
/*   


.left-table .rdt_TableBody #cell-2-undefined{
  min-width: 70px;
  max-width: 70px;
}

.left-table .rdt_TableBody #cell-3-undefined{
  min-width: 70px;
  max-width: 70px;
}

.left-table .rdt_TableBody #cell-4-undefined{
  min-width: 70px;
  max-width: 70px;
}

.left-table .rdt_TableBody #cell-5-undefined{
  min-width: 70px;
  max-width: 70px;
}

.left-table .rdt_TableBody #cell-6-undefined{
  min-width: 70px;
  max-width: 70px;
}
.left-table .rdt_Table .rdt_TableHeadRow:nth-child(0) div:nth-child(1) {
  min-width: 70px;
  max-width: 70px;
}

.left-table .rdt_Table .rdt_TableHeadRow:nth-child(1) div:nth-child(1) {
  min-width: 70px;
  max-width: 70px;
}
.left-table .rdt_Table .rdt_TableHeadRow:nth-child(2) div:nth-child(1) {
  min-width: 70px;
  max-width: 70px;
}

.left-table .ikexHN {
  padding: 0%;
} */
.keywords-table .rdt_Table .rdt_TableBody #cell-1-undefined div:first-child {
  overflow: inherit;
  text-overflow: clip
}

.keywords-table.grouped .rdt_Table .rdt_TableHeadRow div:nth-child(5),
.keywords-table.grouped .rdt_Table .rdt_TableHeadRow div:nth-child(6),
.keywords-table.grouped .rdt_Table .rdt_TableHeadRow div:nth-child(7) {
  min-width: 10px;
  max-width: 70px;
}

.keywords-table.grouped .rdt_Table .rdt_TableBody #cell-4-undefined,
.keywords-table.grouped .rdt_Table .rdt_TableBody #cell-5-undefined,
.keywords-table.grouped .rdt_Table .rdt_TableBody #cell-6-undefined {
  min-width: 10px;
  max-width: 70px;
}

.bwdzsl {
  color: rgb(207 164 164 / 54%) !important;
}

.elipics-text {

  max-width: 280px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.keywords-table #cell-1-undefined div:first-child {
  white-space: normal !important;
  text-overflow: normal !important;
  overflow: visible !important;
}

.keywords-table #cell-3-undefined div:first-child {
  white-space: initial;
  overflow: visible;
}

.keywords-table #cell-6-undefined div:first-child {
  white-space: normal !important;
  text-overflow: normal !important;
  overflow: visible !important;
}

.rdrDefinedRangeWrapperSelected {
  color: re;
}

.custom-popover .custom-popover-header {
  padding: 10px 20px;
  color: #757779;
}

.custom-popover .custom-popover-header p {
  margin-bottom: 0;
}

.header-border {
  border-bottom: 1px solid #ccc;
}

.custom-popover .rdrStaticRange {
  border-bottom: none;
  position: relative;
}

.edit-box {
  margin: 10px 0px;
}

/* .left-table {
  overflow-x: hidden !important;
  table-layout: fixed !important;
} */
.left-table .rdt_TableHead {
  background-color: var(--header-color) !important;
}

/* .left-table .rdt_TableBody{
  overscroll-behavior-x: none !important;
  overflow-x: hidden !important;
} */
.custom-popover .rdrStaticRange:hover .rdrStaticRangeLabel,
/* .custom-popover .rdrStaticRange:focus .rdrStaticRangeLabel{
  background: none !important;
  color: #428bda;
} */
/* .custom-popover .rdrStaticRange:hover .rdrStaticRangeLabel::after
.custom-popover .rdrStaticRange:focus .rdrStaticRangeLabel::after{
  content: \f00c;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateX(-50%);
  font-family: Font Awesome 6 Brands;
} */
.header-top-border {
  border-top: 1px solid #ccc;
}

.custom-popover-footer p {
  padding: 10px 20px;
  margin-bottom: 0;
}

.table-aligned .rdt_Table .rdt_TableHeadRow div:nth-child(2) {
  min-width: 575px;
  max-width: 600px;
}

.table-aligned .rdt_Table .rdt_TableBody .rdt_TableRow #cell-1-undefined {
  min-width: 507px;
  max-width: 600px;
  margin-left: 45px;
}

.table-aligned-grouped .rdt_Table .rdt_TableHeadRow div:nth-child(2) {
  min-width: 430px;
  max-width: 600px;

}

.table-aligned-grouped .rdt_Table .rdt_TableBody .rdt_TableRow #cell-1-undefined {
  min-width: 382px;
  max-width: 600px;
  margin-left: 70px;
}

.label-name.main {
  width: 75% !important;
  margin-left: 20px !important;
}

.label-name,
.label-name-search {
  font-size: 13px !important;
  border: 1px solid #e5e5e5;
  border-radius: 2px !important;
  padding: 1px 10px !important
}

.lable-HR {
  border: 1px solid #9a9a9a;
}

.label-name:focus-visible,
.label-name-search:focus-visible {
  border-color: #e5e5e5;
  outline: none !important;
}

.label-name::placeholder {
  color: #6c757d !important;
  /* padding-left: 10px !important; */
}

.label-name-search::placeholder {
  color: #6c757d !important;
  /* padding-left: 10px !important; */
}

.item-label-name .action-button button {
  font-size: 11px;
  margin-left: 5px;
  padding: 0 5px;
}

.item-label-name .btn-outline-success {
  border: 1px solid #68C8F1;
  color: black;
}

.item-label-name .btn-outline-success:hover {
  background-color: #68C8F1;
  color: white;
  border: 1px solid #68C8F1;

}

.item-label-name .btn-outline-danger {
  border: 1px solid #ED6D7B;
  color: black;
}

.item-label-name .btn-outline-danger:hover {
  background-color: #ED6D7B;
  color: white;
  border: 1px solid #ED6D7B;

}

.basic-single-select {
  max-height: 25px;
  width: 200px !important;
  font-size: 13px;
}
.range-selector {
  font-size: 12px;
  color: #2a2a2a;

}
.range-selector input {
   width: 60px ;
  border: 1px solid #e6e6e6;

}
.basic-single-select>:nth-child(3) {
  min-height: 25px;
  height: 25px;
  flex-wrap: nowrap;
  border-color: #e5e5e5;
  border-radius: 0 !important;
}

.basic-single-select:focus,
.basic-single-select>div:focus {
  border-color: #86b7fe !important;
}

.custom-menu-open>:nth-child(4) {
  max-height: 260px !important;
  /* Set the desired minimum height for the menu */
  min-height: 260px !important;
  z-index: 4;
  font-size: 11px !important;
}

.custom-menu-open>:last-child div {
  max-height: 260px !important;
  overflow-x: hidden !important;
  font-size: 11px !important;
}

.para-check {
  min-width: 60px;
  display: flex;
  align-items: end;
}

.para-check p {
  display: inline-block;
  float: left;
  max-width: 180px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.para-check .form-check {
  padding-left: 30px;
  margin-bottom: 0;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.keywords-table .sc-fLQRDB .rdt_Table .rdt_TableHead .rdt_TableHeadRow .rdt_TableCol .rdt_TableCol_Sortable .hcgwbo {
  white-space: nowrap;
  text-overflow: normal !important;
  overflow: visible !important;
}

/* .keywords-table .jPphKt .rdt_Table .rdt_TableBody .rdt_TableRow .diRLQD div:first-child{
  white-space: normal !important;
  text-overflow: normal !important;
  overflow: visible !important;
} */

.keywords-table.group-overflow .rdt_Table .rdt_TableBody .rdt_TableRow #cell-4-undefined div:first-child {
  white-space: normal !important;
  text-overflow: normal !important;
  overflow: visible !important;
}

.label-list-view {
  border: 1px solid #e5e5e5;
  padding: 20px 10px;
  margin-top: 10px;
  border-radius: 2px !important;
  position: sticky;
}

.seach-box {
  position: sticky;
  z-index: 2;
  background: #fff;
  top: 0;
}
.menu-outline {
  border: 1px solid #ccc !important;
  background-color: transparent !important;
  color: #000 !important;
  max-width: 40px;
  max-height: 40px;
  margin-bottom: 20px;
}

.sidebar-custom ul {
  list-style-type: none;
  padding-left: 0;
}

.sidebar-custom ul li {
  padding: 10px 0;
  border-bottom: 1px solid #ccc;
}

.sidebar-custom ul li a {
  padding-left: 10px !important;
  color: #000;
}

.sidebar-custom ul li:hover {
  background-color: #DEEBFF;
  cursor: pointer;
  color: #fff;
}

.sidebar-active {
  background-color: #b4cff8 !important;
  cursor: pointer;
}

.sidebar-custom ul li:hover a {
  cursor: pointer;
}
.generate-content-tabs{
  width: 63%;
  font-size: 13px;
  margin: auto;
  border-bottom: none !important;
  justify-content: center;
}
.generate-content-tabs li.nav-item .nav-link{
  width: 140px !important;
  max-width: 140px;
  border: none;
  color: rgb(33, 37, 41);
}
.generate-content-tabs li.nav-item .nav-link:focus-visible{
  box-shadow: none;
}
.generate-content-tabs li.nav-item:focus-visible {
  outline: none;
}
.buttons-alignments{
  height: 60px;
  align-items: end !important;
}
@media (max-width: 600px) {
  .form {
    width: 90%;
  }

  .heading h1 {
    font-size: 2rem;
  }

  .heading p {
    font-size: 1.5rem;
  }
}

.w-100 {
  width: 100%;
}

.btn-black {
  display: flex !important;
  justify-content: center;
  align-items: center;
}

.btn-black:hover a {
  color: #fff !important;
}

.custom-padding {
  padding: 0 90px !important;
}

.file-keywords-input {
  padding: 2px 10px !important;
}

.keywords-table.grouped-h2 .rdt_Table .rdt_TableHeadRow div:nth-child(2) {
  max-width: 80px;
}

.keywords-table.grouped-h2 .rdt_Table .rdt_TableHeadRow div:nth-child(5) {
  max-width: 150px;
}

.keywords-table.grouped-h2 .rdt_Table .rdt_TableHeadRow div:nth-child(6),
.keywords-table.grouped-h2 .rdt_Table .rdt_TableHeadRow div:nth-child(7) {
  min-width: 10px;
  max-width: 70px;
}

.keywords-table.grouped-h2 .rdt_Table .rdt_TableBody #cell-1-undefined {
  max-width: 80px;
}

.keywords-table.grouped-h2 .rdt_Table .rdt_TableBody #cell-4-undefined {
  max-width: 150px;
}

.keywords-table.grouped-h2 .rdt_Table .rdt_TableBody #cell-5-undefined,
.keywords-table.grouped-h2 .rdt_Table .rdt_TableBody #cell-6-undefined {
  min-width: 10px;
  max-width: 70px;
}

.visibilty-hidden {
  visibility: hidden;
}

.plus-circle {
  width: 30px;
  height: 30px;
  cursor: pointer;
}

.full-width {
  width: 100%;
}

.border-radius-3 {
  border-radius: 3px;
}

.ml-1 {
  margin-left: 5px;
}

.ngrams-pagination .rdt_Pagination {
  position: relative !important;
  left: 0px;
}

.ngrams-pagination .rdt_Pagination #pagination-first-page,
.ngrams-pagination .rdt_Pagination #pagination-previous-page,
.ngrams-pagination .rdt_Pagination #pagination-next-page,
.ngrams-pagination .rdt_Pagination #pagination-last-page {
  width: 20px;
  padding: 0;
}

.infinity-symbol {
  font-size: calc(5px + 100%);
}

.keywords-table.ngram .rdt_Table .rdt_TableBody #cell-3-undefined div:first-child,
.keywords-table.ngram .rdt_Table .rdt_TableBody #cell-4-undefined div:first-child {
  text-overflow: clip !important;
  overflow: visible !important;
  white-space: normal !important;
}

.projects-table .rdt_TableHead .rdt_TableHeadRow>div:first-child>div:first-child {
  padding-left: 50px;
}

.keywords-table.generate-H2s .rdt_Table .rdt_TableBody #cell-1-undefined,
.keywords-table.generate-H2s .rdt_Table .rdt_TableBody #cell-2-undefined {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;
}

.keywords-table.generate_H2s .rdt_Table .rdt_TableBody #cell-1-undefined {
  padding-right: 0;
  padding-left: 6px;
  width: 100%;
}

.keywords-table.generate_H2s .rdt_Table .rdt_TableBody #cell-2-undefined {
  padding: 5px;
  padding-left: 20px !important;
  margin-left: 12px !important;
}

.keywords-table.generate_H2s .rdt_Table .rdt_TableBody #cell-3-undefined {
  padding: 5px;
}

.keywords-table.generate_H2s .rdt_Table .rdt_TableHead .rdt_TableHeadRow div:nth-child(1) {
  padding-left: 0px !important;
  margin-left: 2px !important;
}

.keywords-table.generate_H2s .rdt_Table .rdt_TableHead .rdt_TableHeadRow div:nth-child(2) {
  padding-left: 28px !important;
}

.keywords-table.generate_H2s .rdt_Table .rdt_TableHead .rdt_TableHeadRow div:nth-child(3) {
  padding-left: 28px !important;
}


.generate-slider .slider {
  height: 4px;
  padding: 4px;
  background-color: #e5e5e5;
}

.generate-slider .slider:focus {
  background-color: #e5e5e5;
  border-color: #e5e5e5;
}

.generate-slider .slider-label,
.generate-slider .slider-value {
  font-size: 13px;
}

.slider-label{
  font-size: 12px;
}

.generate-slider .slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  border-radius: 50px;
  background: #cccccc;
  cursor: pointer;
}

.generate-btn,
.create-project-btn,
.create-content-btn {
  background-color: #01796f !important;
}
.export-btn{
  background-color: #014779 !important;
}
.generate-content-tabs li.nav-item .nav-link.active,
.generate-content-tabs li.nav-item .nav-link:hover{
  border-bottom: 2px solid blue !important;
  border-color: 0 !important;
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
  margin-bottom: 10px !important;
}

.initial-stats strong{
  font-size: 13px;
}
.initial-stats .column2 span{
  font-size: 13px;
}
.stats{
  padding-left: 10px;
  text-align: left;
  list-style: none;
}
.stats span strong{
  font-size: 12px !important;

}

@media screen and (min-width:2451px) {
  .tabs-section .search-page-sec {
    left: auto !important;
    right: -455px;
    top: 50px;
  }

  .single-btn.tabs-section .search-page-sec {
    left: auto !important;
    right: -600px;
  }

  .w-35 {
    width: 35% !important;
  }

  .tabs-section .search-page-sec .form-control.w-35 {
    width: 28% !important;
  }

  .keywords-table {
    height: 73vh;
  }

  /* .keywords-table.ungrouped .rdt_TableHead .rdt_TableHeadRow div:nth-child(2) {
    min-width: 920px !important;
    max-width: 62px !important;
  } */
  .keywords-table.ungrouped #cell-1-undefined {
    min-width: 280px !important;
  }

  .keywords-table.ungrouped .rdt_TableHead .rdt_TableHeadRow div:nth-child(3) {
    min-width: 270px !important;
  }

  .keywords-table.ungrouped .rdt_TableHead .rdt_TableHeadRow div:nth-child(4) {
    min-width: 565px !important;
  }

  .keywords-table.blocked-table .rdt_TableHead .rdt_TableHeadRow div:nth-child(4) {
    min-width: 65px !important;
    max-width: 80px;
  }

  .keywords-table.ungrouped .rdt_TableHead .rdt_TableHeadRow div:nth-child(5),
  .keywords-table.ungrouped .rdt_TableHead .rdt_TableHeadRow div:nth-child(6),
  .keywords-table.ungrouped .rdt_TableHead .rdt_TableHeadRow div:nth-child(7) {
    min-width: 80px !important;
    max-width: 80px !important;
  }

  .keywords-table.blocked-table.ungrouped .rdt_TableHead .rdt_TableHeadRow div:nth-child(5) {
    min-width: 90px !important;
    max-width: 90px !important;
  }

  .keywords-table.ungrouped .rdt_TableHead .rdt_TableHeadRow div:nth-child(8) {
    min-width: 80px !important;
    max-width: 90px !important;
  }

  .keywords-table.ungrouped .rdt_TableHead .rdt_TableHeadRow div:nth-child(4) {
    margin-left: 20px !important;
  }

  .keywords-table.ungrouped .rdt_TableBody #cell-2-undefined {
    min-width: 570px !important;
    max-width: 570px;
  }

  .keywords-table.blocked-table .rdt_TableBody #cell-2-undefined {
    min-width: 70px !important;
    max-width: 70px !important;
  }

  .keywords-table.ungrouped .rdt_TableBody #cell-3-undefined,
  .keywords-table.ungrouped .rdt_TableBody #cell-4-undefined,
  .keywords-table.ungrouped .rdt_TableBody #cell-5-undefined,
  .keywords-table.ungrouped .rdt_TableBody #cell-6-undefined {
    min-width: 80px !important;
    max-width: 80px !important;
  }

  .expandble-table.ungroup-table-aligned .rdt_TableBody #cell-5-undefined {
    padding-left: 30px !important;
  }

  .keywords-table.blocked-table.ungrouped .rdt_TableBody #cell-3-undefined,
  .keywords-table.blocked-table.ungrouped .rdt_TableBody #cell-4-undefined {
    padding-left: 8px !important;
  }

  .keywords-table.ungrouped .rdt_TableBody #cell-6-undefined {
    padding-left: 5px;
  }

  .keywords-table.ungrouped .rdt_TableBody #cell-5-undefined,
  .keywords-table.ungrouped .rdt_TableBody #cell-6-undefined {
    margin-left: 0px !important;
    padding-left: 16px;
  }

  .keywords-table.ungrouped .rdt_TableBody #cell-7-undefined {
    padding-left: 28px !important;
  }

  .expandble-table.ungroup-table-aligned .rdt_TableBody #cell-5-undefined {
    padding-left: 7px !important;
  }

  .keywords-table.grouped .rdt_TableHead .rdt_TableHeadRow div:nth-child(2) {
    min-width: 80px !important;
    max-width: 80px !important;
  }

  .keywords-table.grouped .rdt_TableBody #cell-1-undefined {
    min-width: 80px !important;
    max-width: 80px !important;
  }

  .keywords-table.grouped .rdt_TableHead .rdt_TableHeadRow div:nth-child(3),
  .keywords-table.grouped .rdt_TableBody #cell-2-undefined {
    min-width: 140px !important;
    max-width: 140px !important;
  }

  .keywords-table.grouped .rdt_TableHead .rdt_TableHeadRow div:nth-child(4),
  .keywords-table.grouped #cell-3-undefined {
    min-width: 600px !important;
  }

  .keywords-table.grouped-h2 .rdt_TableHead .rdt_TableHeadRow div:nth-child(2) {
    min-width: 80px !important;
    max-width: 80px !important;
  }

  .keywords-table.grouped-h2 .rdt_TableBody #cell-1-undefined {
    min-width: 80px !important;
    max-width: 80px !important;
  }

  .keywords-table.grouped-h2 .rdt_TableHead .rdt_TableHeadRow div:nth-child(3),
  .keywords-table.grouped-h2 .rdt_TableBody #cell-2-undefined {
    min-width: 140px !important;
    max-width: 140px !important;
  }

  .keywords-table.grouped-h2 .rdt_TableHead .rdt_TableHeadRow div:nth-child(4),
  .keywords-table.grouped-h2 #cell-3-undefined {
    min-width: 150px !important;
    max-width: 150px;
  }

  .keywords-table.grouped-h2 .rdt_TableHead .rdt_TableHeadRow div:nth-child(5),
  .keywords-table.grouped-h2 #cell-4-undefined {
    min-width: 520px !important;
    max-width: 520px;
  }

  .projects-details-tab .nav-item {
    width: auto;
    white-space: nowrap;
  }

  .file-upload-btn {
    width: 50% !important;
  }

  /* .projects-details-tab .nav-item .nav-link{
    min-width: 120px;
    justify-content: center;
    display: flex;
  } */
  .keywords-table.grouped-aligned .rdt_Table .rdt_TableHeadRow div:nth-child(3) {
    max-width: 290px !important;
    min-width: 290px !important;
  }

  .keywords-table.grouped-table-aligned .rdt_Table .rdt_TableHeadRow div:nth-child(3) {
    max-width: 290px !important;
    min-width: 290px !important;
  }

  .keywords-table.grouped-table-aligned .rdt_Table .rdt_TableHeadRow div:nth-child(4) {
    max-width: 590px !important;
    min-width: 590px !important;
  }

  .keywords-table.grouped-aligned .rdt_Table .rdt_TableHeadRow div:nth-child(5),
  .keywords-table.grouped-aligned .rdt_Table .rdt_TableHeadRow div:nth-child(6),
  .keywords-table.grouped-aligned .rdt_Table .rdt_TableHeadRow div:nth-child(7),
  .keywords-table.grouped-aligned .rdt_Table .rdt_TableHeadRow div:nth-child(8) {
    min-width: 80px;
    max-width: 80px;
  }

  .keywords-table.grouped-aligned .rdt_Table .rdt_TableBody #cell-1-undefined {
    max-width: 290px !important;
    min-width: 290px !important;
  }

  .keywords-table.grouped-table-aligned .rdt_Table .rdt_TableBody #cell-2-undefined {
    max-width: 590px !important;
    min-width: 590px !important;
  }

  .keywords-table.grouped-aligned .rdt_Table .rdt_TableBody #cell-3-undefined,
  .keywords-table.grouped-aligned .rdt_Table .rdt_TableBody #cell-4-undefined,
  .keywords-table.grouped-aligned .rdt_Table .rdt_TableBody #cell-5-undefined,
  .keywords-table.grouped-aligned .rdt_Table .rdt_TableBody #cell-6-undefined {
    min-width: 80px;
    max-width: 80px;
  }

  .expandble-table.table-aligned-grouped .rdt_Table .rdt_TableBody #cell-1-undefined {
    min-width: 520px !important;
    max-width: 520px !important;
  }

  .expandble-table .rdt_Table .rdt_TableBody #cell-2-undefined,
  .expandble-table .rdt_Table .rdt_TableBody #cell-3-undefined,
  .expandble-table .rdt_Table .rdt_TableBody #cell-4-undefined {
    min-width: 80px;
    max-width: 80px;
  }

  .group-row-aligned .rdt_Table .rdt_TableBody #cell-1-undefined {
    margin-left: 22px !important;
  }

  .expandble-table.group-row-aligned .rdt_Table .rdt_TableBody #cell-2-undefined,
  .expandble-table.group-row-aligned .rdt_Table .rdt_TableBody #cell-3-undefined,
  .expandble-table.group-row-aligned .rdt_Table .rdt_TableBody #cell-4-undefined {
    min-width: 80px !important;
    max-width: 80px !important;
  }

  .left-table .rdt_Table .rdt_TableHead .rdt_TableHeadRow .rdt_TableCol {
    min-width: 80px;
    max-width: 80px;
    padding: 0 5px !important;
    font-size: 16px;
  }

  .left-table .rdt_Table .rdt_TableBody .rdt_TableCell {
    min-width: 80px;
    max-width: 80px;
    padding: 0 5px !important;
    font-size: 16px;
  }

  /* .left-panel.tokens{
    max-height: 510px;
  } */
  .single-btn .basic-single-select {
    width: 250px !important;
  }

  .single-btn .group-btn {
    width: 80px;
    min-width: 80px;
  }
}
.keywords-table .rdt_Table .rdt_TableHead .rdt_TableHeadRow .rdt_TableCol .rdt_TableCol_Sortable div:first-child{
  overflow: visible;
}
@media screen and (min-width: 2451px) and (max-width:2560px) {
  .table-aligned-grouped .rdt_Table .rdt_TableBody .rdt_TableRow #cell-1-undefined {
    min-width: 542px;
    max-width: 600px;
    margin-left: 70px;
  }

  .table-aligned .rdt_Table .rdt_TableBody .rdt_TableRow #cell-1-undefined {
    min-width: 583px;
    max-width: 615px;
    margin-left: 45px;
  }

  .expand-gh2-tab .rdt_Table .rdt_TableBody .rdt_TableRow #cell-1-undefined {
    min-width: 668px;
  }

  .expandble-table .rdt_Table .rdt_TableBody #cell-2-undefined,
  .expandble-table .rdt_Table .rdt_TableBody #cell-3-undefined {
    min-width: 88px !important;
    max-width: 83px !important;
  }

  .expandble-table .rdt_Table .rdt_TableBody #cell-4-undefined {
    min-width: 73px !important;
    max-width: 83px !important;
  }

  .expandble-table.ungroup-table-aligned .rdt_Table .rdt_TableBody #cell-2-undefined {
    padding-left: 13px !important;
  }

  .expandble-table .rdt_Table .rdt_TableBody #cell-3-undefined {
    padding-left: 20px;
  }

  .expandble-table.ungroup-table-aligned .rdt_Table .rdt_TableBody #cell-3-undefined {
    padding-left: 6px !important;
  }

  .expandble-table .rdt_Table .rdt_TableBody #cell-4-undefined {
    /* margin-left: 12px !important; */
    padding-left: 8px;
  }

  .copy-icon {
    position: absolute;
    right: 0;
  }

  .keywords-table.ungrouped .rdt_Table .rdt_TableBody #cell-1-undefined {
    min-width: 285px !important;
    max-width: 315px;
  }

  .keywords-table.blocked-table.ungrouped .rdt_Table .rdt_TableBody #cell-1-undefined {
    min-width: 1109px !important;
    max-width: 1109px;
  }

  .keywords-table.blocked-table.ungrouped .rdt_Table .rdt_TableBody #cell-2-undefined {
    padding-left: 8px !important;
  }

  .expandble-table.table-aligned-grouped .rdt_Table .rdt_TableBody #cell-1-undefined {
    min-width: 790px !important;
    max-width: 790px !important;
  }

  .expandble-table.exp-grouped-table-aligned .rdt_Table .rdt_TableBody #cell-1-undefined {
    min-width: 962px !important;
    max-width: 962px !important;
  }

  .expandble-table.ungroup-table-aligned .rdt_Table .rdt_TableBody #cell-1-undefined {
    min-width: 922px !important;
    max-width: 937px !important;
  }

  .keywords-table.blocked-table.ungrouped .rdt_TableHead .rdt_TableHeadRow div:nth-child(2) {
    min-width: 1100px !important;
    max-width: 1100px;
  }

  .keywords-table.blocked-table.ungrouped .rdt_TableHead .rdt_TableHeadRow div:nth-child(3) {
    min-width: 70px !important;
    max-width: 70px;
  }

  .keywords-table.blocked-table.ungrouped .rdt_TableHead .rdt_TableHeadRow div:nth-child(4) {
    margin-left: 0px !important;
  }

  .group-row-aligned .rdt_Table .rdt_TableBody #cell-1-undefined {
    margin-left: 22px !important;
  }

  .expandble-table.group-row-aligned .rdt_Table .rdt_TableBody #cell-2-undefined,
  .expandble-table.group-row-aligned .rdt_Table .rdt_TableBody #cell-3-undefined,
  .expandble-table.group-row-aligned .rdt_Table .rdt_TableBody #cell-4-undefined {
    min-width: 70px !important;
    max-width: 80px !important;
  }

  .expandble-table.group-row-aligned .rdt_Table .rdt_TableBody #cell-3-undefined {
    padding-left: 15px !important;
  }

  .expandble-table.group-row-aligned .rdt_Table .rdt_TableBody #cell-4-undefined,
  .expandble-table.group-row-aligned .rdt_Table .rdt_TableBody #cell-5-undefined {
    padding-left: 16px !important;
  }

  .left-table .rdt_Table .rdt_TableHead .rdt_TableHeadRow .rdt_TableCol {
    min-width: 60px;
    max-width: 60px;
    padding: 0 5px !important;
    font-size: 15px;
  }

  .left-table .rdt_Table .rdt_TableBody .rdt_TableCell {
    min-width: 60px;
    max-width: 60px;
    padding: 0 5px !important;
    font-size: 15px;
  }

  .left-table .rdt_Table .rdt_TableHead .rdt_TableHeadRow .rdt_TableCol:first-child,
  .left-table .rdt_Table .rdt_TableBody .rdt_TableCell:first-child {
    min-width: 165px;
    max-width: 175px;
  }

  .left-table .rdt_Table .rdt_TableBody .rdt_TableCell:last-child {
    padding-left: 25px !important;
  }

  .left-table .rdt_Table .rdt_TableHead .rdt_TableHeadRow .rdt_TableCol:nth-child(2),
  .left-table .rdt_Table .rdt_TableBody .rdt_TableCell:nth-child(2) {
    min-width: 40px;
    max-width: 30px;
  }

  .custom-padding {
    padding: 0 30px !important;
  }

  /* .left-panel.tokens{
    max-height: 510px;
  } */
  .ngrams-aligned-grouped .rdt_TableBody #cell-1-undefined {
    margin-left: 0;
    min-width: 100px;
    max-width: 100px;
  }

  .ngrams-aligned-grouped .rdt_TableRow div:nth-child(3) {
    min-width: 23px;
    flex: 0 0 0;
    margin-left: 67px;
  }

  .ngrams-pagination .rdt_Pagination #pagination-first-page,
  .ngrams-pagination .rdt_Pagination #pagination-previous-page,
  .ngrams-pagination .rdt_Pagination #pagination-next-page,
  .ngrams-pagination .rdt_Pagination #pagination-last-page {
    width: 30px;
  }

  .keywords-table.ngram .rdt_Table .rdt_TableBody #cell-1-undefined {
    padding-left: 8px !important;
    min-width: 120px !important;
    max-width: 120px !important;
  }

  .keywords-table.ngram .rdt_Table .rdt_TableBody #cell-2-undefined {
    padding-left: 7px !important;
    min-width: 60px !important;
    max-width: 66px !important;
  }

  .keywords-table.ngram .rdt_Table .rdt_TableBody #cell-3-undefined {
    padding-left: 15px !important;
    min-width: 76px !important;
    max-width: 76px !important;
  }

  /* .keywords-table.ngram .rdt_Table .rdt_TableBody #cell-1-undefined,
  .keywords-table.ngram .rdt_Table .rdt_TableBody #cell-2-undefined,
  .keywords-table.ngram .rdt_Table .rdt_TableBody #cell-3-undefined,
  .keywords-table.ngram .rdt_Table .rdt_TableBody #cell-4-undefined{
    min-width: 80px !important;
    max-width: 80px !important;
  } */
  .ngrams-aligned-grouped.expandable-grams-grouping .rdt_Table .rdt_TableBody #cell-1-undefined {
    padding-left: 0 !important;
    min-width: 58px !important;
    max-width: 120px !important;
  }

  .ngrams-aligned-grouped.expandable-grams-grouping .rdt_Table .rdt_TableBody #cell-2-undefined {
    padding-left: 5px !important;
    min-width: 33px !important;
    max-width: 33px !important;
    /* margin-right: -39px; */
  }

  .ngrams-aligned-grouped.expandable-grams-grouping .rdt_Table .rdt_TableBody #cell-3-undefined {
    padding-left: 24px !important;
  }

  .ngram .rdt_Table .rdt_TableHeadRow div:nth-child(3) div:first-child {
    display: -webkit-inline-box !important;
  }

  .ngram .rdt_Table .rdt_TableHeadRow div:nth-child(3) div:first-child span {
    position: absolute;
    right: 0;
    top: 6px;
  }

  .ngram .rdt_Table .rdt_TableHeadRow div:nth-child(3) div:first-child {
    display: -webkit-inline-box !important;
  }

  .ngram .rdt_Table .rdt_TableHeadRow div:nth-child(3) div:first-child span {
    position: absolute;
    right: 0;
    top: 6px;
  }

  .ngram .rdt_Table .rdt_TableHeadRow div:nth-child(3) div:first-child {
    display: -webkit-inline-box !important;
  }

  .ngram .rdt_Table .rdt_TableHeadRow div:nth-child(3) div:first-child span {
    position: absolute;
    right: 18px;
    top: 7px;
  }

  .ngram .rdt_Table .rdt_TableHeadRow div:nth-child(3) {
    min-width: 120px !important;
    max-width: 120px !important;
  }

  .ngram .rdt_Table .rdt_TableHeadRow div:nth-child(4) {
    min-width: 66px !important;
    max-width: 66px !important;
  }

  .ngram .rdt_Table .rdt_TableHeadRow div:nth-child(5) {
    min-width: 89px !important;
    max-width: 89px !important;
  }

  .ngram .rdt_Table .rdt_TableHeadRow div:nth-child(6) {
    min-width: 20px !important;
    max-width: 20px !important;
  }

  .keywords-table.ngram {
    overflow-x: hidden !important;
  }

  .ngrams-pagination .rdt_Pagination {
    display: flex !important;
    justify-content: flex-start !important;
  }
}

@media screen and (min-width: 2500px) and (max-width:2560px) {
  .keywords-table.ungrouped .rdt_Table .rdt_TableBody #cell-1-undefined {
    min-width: 315px !important;
    max-width: 315px;
  }

  .ngrams-aligned-grouped .rdt_TableBody #cell-1-undefined {
    margin-left: 0px;
    min-width: 100px;
    max-width: 100px;
  }

  .ngrams-aligned-grouped .rdt_TableBody #cell-2-undefined {
    min-width: 49px;
    max-width: 115px;
    padding-right: 0;
  }

  .ngrams-aligned-grouped .rdt_TableBody #cell-3-undefined {
    min-width: 103px;
    max-width: 103px;
    padding-left: 0;
  }

  .keywords-table.blocked-table.ungrouped .rdt_Table .rdt_TableBody #cell-1-undefined {
    min-width: 995px !important;
    max-width: 1140px;
  }

  .keywords-table.blocked-table.ungrouped .rdt_Table .rdt_TableBody #cell-2-undefined {
    padding-left: 17px !important;
  }

  .keywords-table.ungrouped .rdt_TableBody #cell-2-undefined {
    min-width: 603px !important;
    max-width: 700px;
  }

  .keywords-table.blocked-table.ungrouped .rdt_TableBody #cell-2-undefined {
    min-width: 80px !important;
    max-width: 80px;
  }

  .keywords-table.ungrouped .rdt_TableHead .rdt_TableHeadRow div:nth-child(4) {
    min-width: 597px !important;
  }

  .keywords-table.blocked-table.ungrouped .rdt_TableHead .rdt_TableHeadRow div:nth-child(4) {
    min-width: 80px !important;
  }

  .keywords-table.grouped-table-aligned .rdt_Table .rdt_TableHeadRow div:nth-child(4) {
    max-width: 640px !important;
    min-width: 640px !important;
  }

  .keywords-table.grouped-table-aligned .rdt_Table .rdt_TableBody #cell-2-undefined {
    max-width: 640px !important;
    min-width: 640px !important;
  }

  .expandble-table.group-row-aligned .rdt_Table .rdt_TableBody #cell-2-undefined {
    max-width: 80px !important;
    min-width: 80px !important;
  }

  .keywords-table.ungrouped .rdt_TableHead .rdt_TableHeadRow div:nth-child(4) {
    margin-left: 50px !important;
  }

  .expandble-table.ungroup-table-aligned .rdt_Table .rdt_TableBody #cell-2-undefined {
    padding-left: 13px !important;
    min-width: 88px !important;
  }

  .expand-gh2-tab .rdt_Table .rdt_TableBody .rdt_TableRow #cell-1-undefined {
    min-width: 696px !important;
  }

  .expandble-table.ungroup-table-aligned .rdt_Table .rdt_TableBody #cell-1-undefined {
    min-width: 985px !important;
    max-width: 1001px !important;
  }

  .resolution-expand.expandble-table.exp-grouped-table-aligned .rdt_Table .rdt_TableBody #cell-1-undefined {
    min-width: 815px !important;
    max-width: 1012px !important;
  }

  .keywords-table.ngram .rdt_TableBody #cell-4-undefined {
    min-width: 37px !important;
    max-width: 37px !important;
    padding: 0 !important;
  }

  .keywords-table.ngram .rdt_TableBody #cell-5-undefined {
    min-width: 40px !important;
    max-width: 4px !important;
    padding: 0 !important;
  }

  .keywords-table.ngram .rdt_TableHead .rdt_TableHeadRow div:nth-child(3) {
    min-width: 158px !important;
    max-width: 158px !important;
  }

  .keywords-table.ngram .rdt_TableHead .rdt_TableHeadRow div:nth-child(5) {
    min-width: 60px !important;
    max-width: 90px !important;
    padding-left: 0px;
  }

  .keywords-table.ngram .rdt_TableHead .rdt_TableHeadRow div:nth-child(4) {
    min-width: 60px !important;
    max-width: 80px !important;
    padding: 0px;
  }

  .keywords-table.ngram .rdt_TableHead .rdt_TableHeadRow div:nth-child(6) {
    min-width: 30px !important;
    max-width: 40px !important;

  }

  .keywords-table.ngram .rdt_Table .rdt_TableBody #cell-1-undefined {
    min-width: 145px !important;
    max-width: 145px !important;
  }

  .keywords-table.ngram .rdt_Table .rdt_TableBody #cell-2-undefined {
    min-width: 69px !important;
    max-width: 69px !important;
  }

  .keywords-table.blocked-table.ungrouped .rdt_TableHead .rdt_TableHeadRow div:nth-child(2) {
    min-width: 1140px !important;
    max-width: 1100px;
  }

  .ngrams-pagination .rdt_Pagination #pagination-first-page,
  .ngrams-pagination .rdt_Pagination #pagination-previous-page,
  .ngrams-pagination .rdt_Pagination #pagination-next-page,
  .ngrams-pagination .rdt_Pagination #pagination-last-page {
    width: 35px;
  }

  .keywords-table.ngram .rdt_Table .rdt_TableBody #cell-1-undefined {
    padding-left: 8px !important;
  }

  .keywords-table.ngram .rdt_Table .rdt_TableBody #cell-2-undefined {
    padding-left: 5px !important;
  }

  .keywords-table.ngram .rdt_Table .rdt_TableBody #cell-3-undefined {
    padding-left: 4px !important;
  }

  .ngrams-aligned-grouped.expandable-grams-grouping .rdt_Table .rdt_TableBody #cell-1-undefined {
    padding-left: 0px !important;
    min-width: 62px !important;
    max-width: 62px !important;
  }

  .ngrams-aligned-grouped.expandable-grams-grouping .rdt_Table .rdt_TableBody #cell-2-undefined {
    padding-left: 0 !important;
    max-width: 73px !important;
    min-width: 73px !important;
  }

  .ngrams-aligned-grouped.expandable-grams-grouping .rdt_Table .rdt_TableBody #cell-3-undefined {
    padding-left: 3px !important;
    min-width: 60px !important;
    max-width: 60px !important;
  }

  .ngrams-aligned-grouped.expandable-grams-grouping .rdt_Table .rdt_TableBody #cell-4-undefined {
    padding-left: 11px !important;
  }

  .ngram .rdt_Table .rdt_TableHeadRow div:nth-child(3) div:first-child {
    display: -webkit-inline-box !important;
  }

  .ngram .rdt_Table .rdt_TableHeadRow div:nth-child(3) div:first-child span {
    position: absolute;
    right: 54px;
    top: 7px;
  }
}

@media screen and (min-width:2100px) and (max-width: 2450px) {
  .tabs-section .search-page-sec {
    left: auto !important;
    right: -520px;
    top: 46px;
  }

  .single-btn.tabs-section .search-page-sec {
    left: auto !important;
    right: -600px;
  }

  .tabs-section .search-page-sec .form-control.w-35 {
    width: 28% !important;
  }

  .w-35 {
    width: 35% !important;
  }

  /* .keywords-table.ungrouped .rdt_TableHead .rdt_TableHeadRow div:nth-child(2) {
    min-width: 920px !important;
  } */
  .keywords-table.ungrouped #cell-1-undefined {
    min-width: 920px !important;
  }

  .keywords-table.blocked-table.ungrouped #cell-1-undefined {
    min-width: 920px !important;
  }

  .keywords-table.blocked-table.ungrouped .rdt_Table .rdt_TableBody #cell-1-undefined {
    min-width: 1103px !important;
  }

  .keywords-table.ungrouped .rdt_TableHead .rdt_TableHeadRow div:nth-child(3),
  .keywords-table.ungrouped .rdt_TableHead .rdt_TableHeadRow div:nth-child(4),
  .keywords-table.ungrouped .rdt_TableHead .rdt_TableHeadRow div:nth-child(5) {
    min-width: 20px;
    max-width: 180px;
  }

  .keywords-table.ungrouped .rdt_TableHead .rdt_TableHeadRow div:nth-child(3) {
    min-width: 280px !important;
    max-width: 280px !important;
  }

  .keywords-table.ungrouped .rdt_TableHead .rdt_TableHeadRow div:nth-child(4),
  .keywords-table.ungrouped .rdt_TableHead .rdt_TableHeadRow div:nth-child(5) {
    min-width: 68px !important;
    max-width: 70px !important;
  }

  .keyword .keywords-table.ungrouped .rdt_TableHead .rdt_TableHeadRow div:nth-child(3) {
    min-width: 300px !important;
    max-width: 300px !important;
  }

  .keywords-table.ungrouped .rdt_TableHead .rdt_TableHeadRow div:nth-child(4) {
    min-width: 575px !important;
    max-width: 575px !important;
  }

  .keywords-table.blocked-table .rdt_TableHead .rdt_TableHeadRow div:nth-child(4) {
    min-width: 82px !important;
    max-width: 82px !important;
  }

  .keywords-table.ungrouped .rdt_TableHead .rdt_TableHeadRow div:nth-child(5),
  .keywords-table.ungrouped .rdt_TableHead .rdt_TableHeadRow div:nth-child(6),
  .keywords-table.ungrouped .rdt_TableHead .rdt_TableHeadRow div:nth-child(7),
  .keywords-table.ungrouped .rdt_TableHead .rdt_TableHeadRow div:nth-child(8) {
    min-width: 78px !important;
    max-width: 78px !important;
  }

  .keywords-table.ungrouped .rdt_TableHead .rdt_TableHeadRow div:nth-child(6) {
    padding-right: 0 !important;
    min-width: 72px !important;
  }

  .keywords-table.ungrouped .rdt_TableBody #cell-2-undefined,
  .keywords-table.ungrouped .rdt_TableBody #cell-3-undefined,
  .keywords-table.ungrouped .rdt_TableBody #cell-4-undefined {
    min-width: 60px !important;
    max-width: 70px !important;
  }

  .keywords-table.ungrouped .rdt_TableBody #cell-2-undefined {
    min-width: 575px !important;
    max-width: 575px !important;
  }

  .keywords-table.blocked-table .rdt_TableBody #cell-2-undefined {
    min-width: 60px !important;
    max-width: 85px !important;
  }

  .keywords-table.ungrouped .rdt_TableBody #cell-3-undefined,
  /* .keywords-table.ungrouped .rdt_TableBody #cell-4-undefined, */
  .keywords-table.ungrouped .rdt_TableBody #cell-5-undefined .keywords-table.ungrouped .rdt_TableBody #cell-6-undefined {
    min-width: 84px !important;
    max-width: 75px !important;
  }

  .keywords-table.blocked-table.ungrouped .rdt_TableBody #cell-3-undefined {
    min-width: 63px !important;
    max-width: 72px !important;
    padding-left: 11px;
  }

  .keywords-table.ungrouped .rdt_TableBody #cell-4-undefined,
  .keywords-table.ungrouped .rdt_TableBody #cell-5-undefined,
  .keywords-table.ungrouped .rdt_TableBody #cell-6-undefined,
  .keywords-table.ungrouped .rdt_TableBody #cell-7-undefined {
    min-width: 72px !important;
    max-width: 72px !important;
    padding-left: 10px !important;
  }

  .keywords-table.blocked-table .rdt_TableBody #cell-4-undefined {
    padding-left: 4px !important;
  }

  .keywords-table.ungrouped .rdt_TableBody #cell-5-undefined {
    padding-left: 50px;
  }

  .expandble-table.ungroup-table-aligned .rdt_Table .rdt_TableBody #cell-4-undefined {
    padding-left: 18px;
  }

  .keywords-table.grouped .rdt_TableHead .rdt_TableHeadRow div:nth-child(2) {
    min-width: 80px !important;
    max-width: 80px !important;
  }

  .keywords-table.grouped .rdt_TableBody #cell-1-undefined {
    min-width: 80px !important;
    max-width: 80px !important;
  }

  .keywords-table.grouped .rdt_TableHead .rdt_TableHeadRow div:nth-child(3),
  .keywords-table.grouped .rdt_TableBody #cell-2-undefined {
    min-width: 140px !important;
    max-width: 140px !important;
  }

  .keywords-table.grouped .rdt_TableHead .rdt_TableHeadRow div:nth-child(4),
  .keywords-table.grouped #cell-3-undefined {
    min-width: 600px !important;
  }

  .keywords-table.grouped-h2 .rdt_TableHead .rdt_TableHeadRow div:nth-child(2) {
    min-width: 80px !important;
    max-width: 80px !important;
  }

  .keywords-table.grouped-h2 .rdt_TableBody #cell-1-undefined {
    min-width: 80px !important;
    max-width: 80px !important;
  }

  .keywords-table.grouped-h2 .rdt_TableHead .rdt_TableHeadRow div:nth-child(3),
  .keywords-table.grouped-h2 .rdt_TableBody #cell-2-undefined {
    min-width: 140px !important;
    max-width: 140px !important;
  }

  .keywords-table.grouped-h2 .rdt_TableHead .rdt_TableHeadRow div:nth-child(4),
  .keywords-table.grouped-h2 #cell-3-undefined {
    min-width: 150px !important;
    max-width: 150px;
  }

  .keywords-table.grouped-h2 .rdt_TableHead .rdt_TableHeadRow div:nth-child(5),
  .keywords-table.grouped-h2 #cell-4-undefined {
    min-width: 520px !important;
    max-width: 520px;
  }

  .projects-details-tab .nav-item {
    width: auto;
    white-space: nowrap;
  }

  .file-upload-btn {
    width: 50% !important;
  }

  /* .projects-details-tab .nav-item .nav-link{
    min-width: 120px;
    justify-content: center;
    display: flex;
  } */
  .table-aligned-grouped .rdt_Table .rdt_TableBody .rdt_TableRow #cell-1-undefined {
    min-width: 427px;
    max-width: 600px;
    margin-left: 70px;
  }

  .table-aligned .rdt_Table .rdt_TableBody .rdt_TableRow #cell-1-undefined {
    min-width: 530px;
    max-width: 600px;
    margin-left: 45px;
  }

  .expand-gh2-tab .rdt_Table .rdt_TableBody .rdt_TableRow #cell-1-undefined {
    min-width: 607px;
  }

  .keywords-table.grouped-aligned .rdt_Table .rdt_TableHeadRow div:nth-child(3) {
    max-width: 290px !important;
    min-width: 290px !important;
  }

  .keywords-table.grouped-table-aligned .rdt_Table .rdt_TableHeadRow div:nth-child(3) {
    max-width: 290px !important;
    min-width: 290px !important;
  }

  .keywords-table.grouped-table-aligned .rdt_Table .rdt_TableHeadRow div:nth-child(4) {
    max-width: 575px !important;
    min-width: 575px !important;
  }

  .keywords-table.grouped-aligned .rdt_Table .rdt_TableHeadRow div:nth-child(5),
  .keywords-table.grouped-aligned .rdt_Table .rdt_TableHeadRow div:nth-child(6),
  .keywords-table.grouped-aligned .rdt_Table .rdt_TableHeadRow div:nth-child(7),
  .keywords-table.grouped-aligned .rdt_Table .rdt_TableHeadRow div:nth-child(8) {
    min-width: 80px;
    max-width: 80px;
  }

  .keywords-table.grouped-aligned .rdt_Table .rdt_TableBody #cell-1-undefined {
    max-width: 290px !important;
    min-width: 290px !important;
  }

  .keywords-table.grouped-table-aligned .rdt_Table .rdt_TableBody #cell-1-undefined {
    max-width: 290px !important;
    min-width: 290px !important;
  }

  .keywords-table.grouped-table-aligned .rdt_Table .rdt_TableBody #cell-2-undefined {
    max-width: 575px !important;
    min-width: 575px !important;
  }

  .keywords-table.grouped-aligned .rdt_Table .rdt_TableBody #cell-3-undefined,
  .keywords-table.grouped-aligned .rdt_Table .rdt_TableBody #cell-4-undefined,
  .keywords-table.grouped-aligned .rdt_Table .rdt_TableBody #cell-5-undefined,
  .keywords-table.grouped-aligned .rdt_Table .rdt_TableBody #cell-6-undefined {
    min-width: 80px;
    max-width: 80px;
  }

  /* .expandble-table.ungrouped.table-aligned-grouped .rdt_Table .rdt_TableBody #cell-1-undefined{
    min-width: 610px !important;
    max-width: 720px !important;
  } */
  .expandble-table .rdt_Table .rdt_TableBody #cell-2-undefined {
    min-width: 80px !important;
    max-width: 80px !important;
  }

  .expandble-table .rdt_Table .rdt_TableBody #cell-3-undefined,
  .expandble-table .rdt_Table .rdt_TableBody #cell-4-undefined {
    min-width: 82px !important;
    max-width: 82px !important;
  }

  .expandble-table.ungroup-table-aligned .rdt_Table .rdt_TableBody #cell-2-undefined {
    min-width: 89px !important;
    max-width: 82px !important;
    padding-left: 10px;
  }

  .expandble-table.ungroup-table-aligned .rdt_Table .rdt_TableBody #cell-4-undefined {
    min-width: 54px !important;
    max-width: 54px !important;
  }

  .expandble-table.ungroup-table-aligned.ungroup-expansion .rdt_Table .rdt_TableBody #cell-4-undefined {
    min-width: 78px !important;
    max-width: 78px !important;
  }

  .expandble-table.ungroup-table-aligned .rdt_Table .rdt_TableBody #cell-3-undefined {
    /* margin-left: 29px !important; */
    padding-left: 2px;
  }


  .keywords-table.blocked-table.ungrouped .rdt_TableHead .rdt_TableHeadRow div:nth-child(4) {
    min-width: 62px !important;
    max-width: 70px;
  }

  .keywords-table.blocked-table.ungrouped .rdt_TableHead .rdt_TableHeadRow div:nth-child(5) {
    min-width: 70px !important;
    max-width: 70px;
    padding-left: 0px !important;
  }

  .keywords-table.blocked-table.ungrouped .rdt_TableHead .rdt_TableHeadRow div:nth-child(3) {
    min-width: 70px !important;
    max-width: 80px !important;
  }

  .keywords-table.ungrouped .rdt_Table .rdt_TableBody #cell-1-undefined {
    min-width: 280px !important;
    max-width: 280px;
  }

  .table-aligned-grouped.expandble-table .rdt_Table .rdt_TableBody #cell-1-undefined {
    min-width: 833px !important;
    max-width: 828px !important;
  }

  .table-aligned-grouped.exp-grouped-table-aligned .rdt_Table .rdt_TableBody #cell-1-undefined {
    min-width: 802px !important;
    max-width: 802px !important;
  }

  .group-row-aligned .rdt_Table .rdt_TableBody #cell-1-undefined {
    margin-left: 22px !important;
  }

  .expandble-table.group-row-aligned .rdt_Table .rdt_TableBody #cell-2-undefined,
  .expandble-table.group-row-aligned .rdt_Table .rdt_TableBody #cell-3-undefined,
  .expandble-table.group-row-aligned .rdt_Table .rdt_TableBody #cell-4-undefined {
    min-width: 80px !important;
    max-width: 80px !important;
  }

  .left-table .rdt_Table .rdt_TableHead .rdt_TableHeadRow .rdt_TableCol {
    min-width: 40px;
    max-width: 65px;
    padding: 0 5px !important;
    font-size: 11px;
  }

  .left-table .rdt_Table .rdt_TableBody .rdt_TableCell {
    min-width: 50px;
    max-width: 65px;
    padding: 0 5px !important;
    font-size: 11px;
  }

  .left-table .rdt_Table .rdt_TableBody .rdt_TableCell:last-child {
    padding-left: 19px !important;
  }

  .left-panel.tokens {
    max-height: 510px;
  }

  .left-table .rdt_Table .rdt_TableHead .rdt_TableHeadRow .rdt_TableCol:first-child,
  .left-table .rdt_Table .rdt_TableBody .rdt_TableCell:first-child {
    min-width: 140px;
    max-width: 140px;
  }

  .left-table .rdt_Table .rdt_TableHead .rdt_TableHeadRow .rdt_TableCol:nth-child(2),
  .left-table .rdt_Table .rdt_TableBody .rdt_TableCell:nth-child(2) {
    min-width: 40px;
    max-width: 30px;
  }

  .custom-padding {
    padding: 0 30px !important;
  }

  .keywords-table.blocked-table .rdt_TableHead .rdt_TableHeadRow div:nth-child(2) {
    min-width: 959px;
  }

  .ngrams-aligned-grouped .rdt_TableBody #cell-1-undefined {
    margin-left: 0;
    min-width: 34px;
    max-width: 100px;
  }

  .ngrams-aligned-grouped .rdt_TableRow div:nth-child(3) {
    min-width: 23px;
    flex: 0 0 0;
    margin-left: 67px;
  }

  .ngram .rdt_Table .rdt_TableHeadRow div:nth-child(3) div:first-child {
    display: -webkit-inline-box !important;
  }

  .ngram .rdt_Table .rdt_TableHeadRow div:nth-child(3) div:first-child span {
    position: absolute;
    right: 0;
    top: 6px;
  }

  .single-btn .basic-single-select {
    width: 250px !important;
  }

  .single-btn .group-btn {
    width: 80px;
    min-width: 80px;
  }
}

@media screen and (min-width: 2200px) and (max-width:2200px) {

  .keywords-table.ungrouped .rdt_TableHead .rdt_TableHeadRow div:nth-child(4),
  .keywords-table.ungrouped .rdt_TableBody #cell-2-undefined {
    min-width: 465px !important;
    max-width: 465px !important;
  }

  .keywords-table.ungrouped .rdt_TableHead .rdt_TableHeadRow div:nth-child(7),
  .keywords-table.ungrouped .rdt_TableHead .rdt_TableHeadRow div:nth-child(8) {
    min-width: 73px !important;
    max-width: 73px !important;
  }

  .keywords-table.ungrouped .rdt_TableBody #cell-7-undefined {
    padding-left: 13px !important;
  }

  .keywords-table.grouped-aligned .rdt_Table .rdt_TableHeadRow div:nth-child(4),
  .keywords-table.grouped-aligned .rdt_Table .rdt_TableBody #cell-2-undefined {
    max-width: 423px !important;
    min-width: 423px !important;
  }

  .table-aligned-grouped.expandble-table .rdt_Table .rdt_TableBody #cell-1-undefined {
    min-width: 795px !important;
    max-width: 795px !important;
  }

  .expandble-table.ungroup-table-aligned .rdt_Table .rdt_TableBody #cell-1-undefined {
    min-width: 823px !important;
    max-width: 828px !important;
  }

  .expandble-table.group-row-aligned .rdt_Table .rdt_TableBody #cell-2-undefined {
    max-width: 80px !important;
    min-width: 80px !important;
  }

  .keywords-table.blocked-table .rdt_TableHead .rdt_TableHeadRow div:nth-child(2) {
    min-width: 950px !important;
    max-width: 950px !important;
  }

  .keywords-table.blocked-table.ungrouped .rdt_TableHead .rdt_TableHeadRow div:nth-child(4) {
    min-width: 80px !important;
    max-width: 80px !important;
  }

  .keywords-table.blocked-table.ungrouped .rdt_Table .rdt_TableBody #cell-1-undefined {
    min-width: 952px !important;
  }

  .keywords-table.blocked-table.ungrouped .rdt_Table .rdt_TableBody #cell-2-undefined {
    min-width: 80px !important;
    max-width: 80px !important;
  }

  .keywords-table.blocked-table.ungrouped .rdt_Table .rdt_TableBody #cell-3-undefined {
    padding-left: 14px !important;
  }

  .keywords-table.blocked-table.ungrouped .rdt_Table .rdt_TableBody #cell-4-undefined {
    padding-left: 11px !important;
  }

  .keywords-table.ngram .rdt_Table .rdt_TableBody #cell-1-undefined {
    padding-left: 8px !important;
  }

  .keywords-table.ngram .rdt_Table .rdt_TableBody #cell-2-undefined {
    padding-left: 7px !important;
    min-width: 66px !important;
    max-width: 66px !important;
  }

  .keywords-table.ngram .rdt_Table .rdt_TableBody #cell-3-undefined {
    padding-left: 10px !important;
    min-width: 50px !important;
    max-width: 50px !important;
  }

  .ngrams-aligned-grouped.expandable-grams-grouping .rdt_Table .rdt_TableBody #cell-1-undefined {
    padding-left: 0px !important;
  }

  .ngrams-aligned-grouped.expandable-grams-grouping .rdt_Table .rdt_TableBody #cell-2-undefined {
    padding-left: 29px !important;
    min-width: 62px !important;
    max-width: 62px !important;
  }

  .ngrams-aligned-grouped.expandable-grams-grouping .rdt_Table .rdt_TableBody #cell-3-undefined {
    padding-left: 0 !important;
  }

  .ngrams-aligned-grouped.expandable-grams-grouping .rdt_Table .rdt_TableBody #cell-4-undefined {
    padding-left: 19px !important;
  }

  .ngram .rdt_Table .rdt_TableHeadRow div:nth-child(3) div:first-child {
    display: -webkit-inline-box !important;
  }

  .ngram .rdt_Table .rdt_TableHeadRow div:nth-child(3) div:first-child span {
    position: absolute;
    right: 0;
    top: 6px;
  }

  .ngram .rdt_Table .rdt_TableHeadRow div:nth-child(3) {
    min-width: 100px !important;
    max-width: 100px !important;
  }

  .ngram .rdt_Table .rdt_TableHeadRow div:nth-child(4) {
    min-width: 66px !important;
    max-width: 66px !important;
  }

  .ngram .rdt_Table .rdt_TableHeadRow div:nth-child(5) {
    min-width: 89px !important;
    max-width: 89px !important;
  }

  .keywords-table.ngram {
    overflow-x: hidden !important;
  }

  .ngrams-pagination .rdt_Pagination {
    display: flex !important;
    justify-content: flex-start !important;
  }

  .tabs-section .search-page-sec .form-control.w-35 {
    width: 25% !important;
  }
}

@media screen and (min-width: 2450px) and (max-width: 2450px) {
  .keywords-table.ungrouped .rdt_TableBody #cell-5-undefined {
    padding-left: 15px !important;
  }

  .keywords-table.ungrouped .rdt_TableBody #cell-6-undefined {
    padding-left: 22px !important;
  }

  .keywords-table.ungrouped .rdt_TableBody #cell-7-undefined {
    padding-left: 30px !important;
    min-width: 85px !important;
    max-width: 85px !important;
  }

  .keywords-table.ungrouped .rdt_TableBody #cell-7-undefined div:first-child,
  .expandble-table.ungroup-table-aligned .rdt_Table .rdt_TableBody #cell-5-undefined div:first-child {
    overflow: visible !important;
  }

  .table-aligned-grouped.exp-grouped-table-aligned .rdt_Table .rdt_TableBody #cell-1-undefined {
    min-width: 947px !important;
    max-width: 947px !important;
  }

  .expandble-table.ungroup-table-aligned .rdt_Table .rdt_TableBody #cell-1-undefined {
    min-width: 938px !important;
    max-width: 938px !important;
  }

  .expandble-table.ungroup-table-aligned .rdt_Table .rdt_TableBody #cell-3-undefined {
    /* margin-left: 29px !important; */
    padding-left: 7px !important;
  }

  .expandble-table.ungroup-table-aligned .rdt_TableBody #cell-5-undefined {
    padding-left: 30px !important;
  }

  .keywords-table.blocked-table.ungrouped .rdt_Table .rdt_TableBody #cell-1-undefined {
    min-width: 1095px !important;
  }

  .keywords-table.ungrouped .rdt_TableBody #cell-4-undefined,
  .keywords-table.ungrouped .rdt_TableBody #cell-5-undefined,
  .keywords-table.ungrouped .rdt_TableBody #cell-6-undefined {
    min-width: 91px !important;
    max-width: 91px !important;
  }

  .expand-gh2-tab .rdt_Table .rdt_TableBody .rdt_TableRow #cell-1-undefined {
    min-width: 670px !important;
  }

  .expand-gh2-tab .rdt_Table .rdt_TableBody .rdt_TableRow #cell-3-undefined {
    padding-left: 14px !important;
  }

  .keywords-table.blocked-table .rdt_TableBody #cell-2-undefined {
    min-width: 85px !important;
    max-width: 85px !important;
  }

  .keywords-table.blocked-table .rdt_TableBody #cell-4-undefined {
    padding-left: 5px !important;
  }

  .keywords-table.ungrouped .rdt_TableBody #cell-4-undefined,
  .keywords-table.ungrouped .rdt_TableBody #cell-5-undefined,
  .keywords-table.ungrouped .rdt_TableBody #cell-6-undefined {
    min-width: 60px !important;
    max-width: 72px !important;
  }

  .keywords-table.ngram .rdt_Table .rdt_TableBody #cell-1-undefined {
    padding-left: 8px !important;
    min-width: 120px !important;
    max-width: 120px !important;
  }

  .keywords-table.ngram .rdt_Table .rdt_TableBody #cell-2-undefined {
    padding-left: 8px !important;
    min-width: 60px !important;
    max-width: 60px !important;
  }

  .keywords-table.ngram .rdt_Table .rdt_TableBody #cell-3-undefined {
    padding-left: 14px !important;
    min-width: 49px !important;
    max-width: 49px !important;
  }

  .ngrams-aligned-grouped.expandable-grams-grouping .rdt_Table .rdt_TableBody #cell-1-undefined {
    padding-left: 0 !important;
    min-width: 55px !important;
    max-width: 55px !important;
  }

  .ngrams-aligned-grouped.expandable-grams-grouping .rdt_Table .rdt_TableBody #cell-2-undefined {
    /* padding-left: 6px !important; */
    max-width: 62px !important;
    min-width: 62px !important;
  }

  .ngrams-aligned-grouped.expandable-grams-grouping .rdt_Table .rdt_TableBody #cell-3-undefined {
    padding-left: 0 !important;
  }

  .ngram .rdt_Table .rdt_TableHeadRow div:nth-child(3) div:first-child {
    display: -webkit-inline-box !important;
  }

  .ngram .rdt_Table .rdt_TableHeadRow div:nth-child(3) div:first-child span {
    position: absolute;
    right: 0;
    top: 6px;
  }

  .ngram .rdt_Table .rdt_TableHeadRow div:nth-child(3) div:first-child {
    display: -webkit-inline-box !important;
  }

  .ngram .rdt_Table .rdt_TableHeadRow div:nth-child(3) div:first-child span {
    position: absolute;
    right: 18px;
    top: 7px;
  }

  .ngram .rdt_Table .rdt_TableHeadRow div:nth-child(3) {
    min-width: 120px !important;
    max-width: 120px !important;
  }

  .ngram .rdt_Table .rdt_TableHeadRow div:nth-child(4) {
    min-width: 66px !important;
    max-width: 66px !important;
  }

  .ngram .rdt_Table .rdt_TableHeadRow div:nth-child(5) {
    min-width: 89px !important;
    max-width: 89px !important;
  }

  .ngram .rdt_Table .rdt_TableHeadRow div:nth-child(6) {
    min-width: 20px !important;
    max-width: 20px !important;
  }

  .keywords-table.ngram {
    overflow-x: hidden !important;
  }

  .ngrams-pagination .rdt_Pagination {
    display: flex !important;
    justify-content: flex-start !important;
  }
}

@media screen and (min-width:1920px) and (max-width: 2099px) {

  .ngrams-aligned-grouped .rdt_TableBody #cell-1-undefined {
    min-width: 103px;
    max-width: 103px;
    margin-left: 54px;
  }

  .tabs-section .search-page-sec {
    left: 73% !important;
    right: 0;
    top: 50px;
  }

  .single-btn.tabs-section .search-page-sec {
    left: 100% !important;
    right: 0;
  }

  .single-btn .basic-single-select {
    width: 220px !important;
  }

  .single-btn .group-btn {
    width: 80px;
    min-width: 80px;
  }

  .single-btn.tabs-section .search-page-sec .form-control.w-35 {
    width: 35% !important;
  }

  .tabs-section .search-page-sec .form-control.w-35 {
    width: 27% !important;
  }

  /* .keywords-table.ungrouped .rdt_TableHead .rdt_TableHeadRow div:nth-child(1) {
    min-width: 30px;
  } */
  .keywords-table.ungrouped.overflow,
  .keywords-table.group-overflow {
    overflow-x: hidden !important;
  }

  .keywords-table.ungrouped #cell-1-undefined {
    min-width: 195px !important;
    max-width: 200px !important;
    padding-right: 0 !important;
  }

  .keywords-table.blocked-table.ungrouped .rdt_Table .rdt_TableBody #cell-1-undefined {
    min-width: 729px !important;
  }

  .keywords-table.ungrouped .rdt_TableHead .rdt_TableHeadRow div:nth-child(3) {
    min-width: 195px !important;
    max-width: 200px !important;
  }

  .keywords-table.blocked-table .rdt_TableHead .rdt_TableHeadRow div:nth-child(3) {
    min-width: 148px !important;
    max-width: 148px !important;
  }

  .keywords-table.ungrouped .rdt_TableHead .rdt_TableHeadRow div:nth-child(4) {
    min-width: 390px;
    max-width: 390px;
  }

  .keywords-table.ungrouped .rdt_TableHead .rdt_TableHeadRow div:nth-child(5) {
    min-width: 77px;
    max-width: 77px;
  }

  .keywords-table.ungrouped .rdt_TableHead .rdt_TableHeadRow div:nth-child(6) {
    min-width: 84px;
    max-width: 84px;
  }

  .keywords-table.ungrouped .rdt_TableHead .rdt_TableHeadRow div:nth-child(7),
  /* .keywords-table.ungrouped .rdt_TableHead .rdt_TableHeadRow div:nth-child(8), */
  .keywords-table.ungrouped .rdt_TableHead .rdt_TableHeadRow div:nth-child(9) {
    min-width: 72px;
    max-width: 72px;
  }

  .keywords-table.ungrouped .rdt_TableHead .rdt_TableHeadRow div:nth-child(8) {
    min-width: 65px;
    max-width: 65px;
  }

  .keywords-table.ungrouped .rdt_TableHead .rdt_TableHeadRow div:nth-child(9) {
    min-width: 92px;
    max-width: 92px;
    margin-right: 20px;
  }

  .keywords-table.blocked-table.ungrouped .rdt_TableHead .rdt_TableHeadRow div:nth-child(2) {
    min-width: 728px !important;
    max-width: 728px !important;
  }

  .keywords-table.blocked-table.ungrouped .rdt_TableHead .rdt_TableHeadRow div:nth-child(3) {
    min-width: 100px !important;
    max-width: 100px !important;
  }

  .keywords-table.blocked-table.ungrouped .rdt_TableHead .rdt_TableHeadRow div:nth-child(4) {
    min-width: 90px !important;
    max-width: 90px !important;
  }

  .keywords-table.blocked-table.ungrouped .rdt_TableHead .rdt_TableHeadRow div:nth-child(5) {
    min-width: 98px !important;
    max-width: 98px !important;
  }

  .keywords-table.ungrouped .rdt_TableBody #cell-2-undefined {
    min-width: 390px !important;
    max-width: 395px !important;
  }

  .keywords-table.blocked-table .rdt_TableBody #cell-2-undefined {
    min-width: 100px !important;
    max-width: 100px !important;
  }

  .keywords-table.ungrouped .rdt_TableBody #cell-3-undefined {
    min-width: 85px !important;
    max-width: 85px !important;
  }

  .keywords-table.blocked-table.ungrouped .rdt_TableBody #cell-3-undefined {
    min-width: 88px !important;
    max-width: 88px !important;
  }

  .keywords-table.ungrouped .rdt_TableBody #cell-4-undefined {
    min-width: 90px !important;
    max-width: 92px !important;
    padding-left: 7px;
  }

  .keywords-table.blocked-table.ungrouped .rdt_TableBody #cell-4-undefined {
    padding-left: 19px;
  }

  .keywords-table.ungrouped .rdt_TableBody #cell-5-undefined {
    margin-left: 0px !important;
    /* padding-left: 1px; */
  }

  .keywords-table.ungrouped .rdt_TableBody #cell-5-undefined {
    min-width: 65px !important;
    max-width: 70px !important;
    padding-left: 3px;
  }

  .keywords-table.ungrouped .rdt_TableBody #cell-6-undefined {
    min-width: 65px !important;
    max-width: 65px !important;
    padding-left: 10px !important;
  }

  .keywords-table.ungrouped .rdt_TableBody #cell-7-undefined {
    padding-left: 10px !important;
  }

  .keywords-table.grouped .rdt_TableHead .rdt_TableHeadRow div:nth-child(2) {
    min-width: 80px !important;
    max-width: 80px !important;
  }

  .keywords-table.grouped .rdt_TableBody #cell-1-undefined {
    min-width: 80px !important;
    max-width: 80px !important;
  }

  .keywords-table.grouped .rdt_TableHead .rdt_TableHeadRow div:nth-child(3),
  .keywords-table.grouped .rdt_TableBody #cell-2-undefined {
    min-width: 140px !important;
    max-width: 140px !important;
    padding-left: 12px !important;
  }

  .keywords-table.grouped .rdt_TableHead .rdt_TableHeadRow div:nth-child(4),
  .keywords-table.grouped #cell-3-undefined {
    min-width: 344px !important;
    padding-left: 20px !important;
  }

  .keywords-table.grouped-h2 .rdt_TableHead .rdt_TableHeadRow div:nth-child(2) {
    min-width: 80px !important;
    max-width: 80px !important;
  }

  .keywords-table.grouped-h2 .rdt_TableBody #cell-1-undefined {
    min-width: 80px !important;
    max-width: 80px !important;
  }

  .keywords-table.grouped-h2 .rdt_TableHead .rdt_TableHeadRow div:nth-child(3),
  .keywords-table.grouped-h2 .rdt_TableBody #cell-2-undefined {
    min-width: 140px !important;
    max-width: 140px !important;
  }

  .keywords-table.grouped-h2 .rdt_TableHead .r95pxdt_TableHeadRow div:nth-child(4),
  .keywords-table.grouped-h2 #cell-3-undefined {
    min-width: 150px !important;
    max-width: 150px;
  }

  .keywords-table.grouped-h2 .rdt_TableHead .rdt_TableHeadRow div:nth-child(5),
  .keywords-table.grouped-h2 #cell-4-undefined {
    min-width: 520px !important;
    max-width: 520px;
  }

  .keywords-table.ngram .rdt_TableHead .rdt_TableHeadRow div:nth-child(3) {
    min-width: 92px !important;
    max-width: 92px !important;
    padding: 0 !important;
  }

  .keywords-table.ngram .rdt_TableHead .rdt_TableHeadRow div:nth-child(5) {
    min-width: 60px !important;
    max-width: 90px !important;
    padding: 0 !important;
  }

  .keywords-table.ngram .rdt_TableHead .rdt_TableHeadRow div:nth-child(4) {
    min-width: 42px !important;
    max-width: 42px !important;
    padding: 0 !important;
  }

  .keywords-table.ngram .rdt_TableHead .rdt_TableHeadRow div:nth-child(6) {
    min-width: 30px !important;
    max-width: 40px !important;
    padding: 0 !important;
  }

  .keywords-table.ngram .rdt_TableBody #cell-2-undefined,
  .keywords-table.ngram .rdt_TableBody #cell-3-undefined {
    min-width: 50px !important;
    max-width: 50px !important;
    padding: 0 !important;
  }

  .keywords-table.ngram .rdt_TableBody #cell-3-undefined {
    padding: 1px !important;
  }

  .keywords-table.ngram .rdt_TableBody #cell-4-undefined {
    min-width: 42px !important;
    max-width: 42px !important;
    padding: 0 !important;
  }

  .keywords-table.ngram .rdt_TableBody #cell-5-undefined {
    min-width: 40px !important;
    max-width: 4px !important;
    padding: 0 !important;
  }

  .projects-details-tab .nav-item {
    width: auto;
    white-space: nowrap;
  }

  .file-upload-btn {
    width: 50% !important;
  }

  /* .projects-details-tab .nav-item .nav-link{
    min-width: 120px;
    justify-content: center;
    display: flex;
  } */
  .keywords-table.grouped-aligned .rdt_Table .rdt_TableHeadRow div:nth-child(3) {
    max-width: 220px !important;
    min-width: 220px !important;
  }

  .keywords-table.grouped-table-aligned .rdt_Table .rdt_TableHeadRow div:nth-child(3) {
    max-width: 220px !important;
    min-width: 280px !important;
  }

  .keywords-table.grouped-table-aligned .rdt_Table .rdt_TableHeadRow div:nth-child(4) {
    max-width: 300px !important;
    min-width: 300px !important;
  }

  .keywords-table.grouped-aligned .rdt_Table .rdt_TableHeadRow div:nth-child(5),
  .keywords-table.grouped-aligned .rdt_Table .rdt_TableHeadRow div:nth-child(6),
  .keywords-table.grouped-aligned .rdt_Table .rdt_TableHeadRow div:nth-child(7),
  .keywords-table.grouped-aligned .rdt_Table .rdt_TableHeadRow div:nth-child(8) {
    min-width: 80px;
    max-width: 80px;
  }

  .keywords-table.grouped-table-aligned .rdt_Table .rdt_TableBody #cell-1-undefined {
    max-width: 220px !important;
    min-width: 280px !important;
  }

  .keywords-table.grouped-table-aligned .rdt_Table .rdt_TableBody #cell-2-undefined {
    max-width: 300px !important;
    min-width: 300px !important;
  }

  .keywords-table.blocked-table .lmTtlo {
    min-width: 575px;
  }

  .expand-gh2-tab .rdt_Table .rdt_TableBody .rdt_TableRow #cell-1-undefined {
    min-width: 540px;
  }

  .expand-gh2-tab .rdt_Table .rdt_TableBody .rdt_TableRow #cell-2-undefined {
    padding-left: 10px !important;
  }

  .expand-gh2-tab .rdt_Table .rdt_TableBody .rdt_TableRow #cell-3-undefined {
    padding-left: 20px !important;
  }

  .expand-gh2-tab .rdt_Table .rdt_TableBody .rdt_TableRow #cell-4-undefined {
    padding-left: 16px !important;
  }

  .keywords-table.grouped-aligned .rdt_Table .rdt_TableBody #cell-3-undefined,
  .keywords-table.grouped-aligned .rdt_Table .rdt_TableBody #cell-4-undefined,
  .keywords-table.grouped-aligned .rdt_Table .rdt_TableBody #cell-5-undefined,
  .keywords-table.grouped-aligned .rdt_Table .rdt_TableBody #cell-6-undefined {
    min-width: 80px;
    max-width: 80px;
  }

  .keywords-table.grouped-aligned .rdt_Table .rdt_TableBody #cell-5-undefined {
    padding-left: 15px !important;
  }

  .expandble-table.table-aligned-grouped .rdt_Table .rdt_TableBody #cell-1-undefined {
    min-width: 520px !important;
    max-width: 520px !important;
  }

  .expandble-table.exp-grouped-table-aligned .rdt_Table .rdt_TableBody #cell-1-undefined {
    min-width: 662px !important;
    max-width: 662px !important;
  }

  .expandble-table.ungroup-table-aligned .rdt_Table .rdt_TableBody #cell-1-undefined {
    min-width: 661px !important;
    max-width: 661px !important;
  }

  .expandble-table .rdt_Table .rdt_TableBody #cell-2-undefined,
  .expandble-table .rdt_Table .rdt_TableBody #cell-3-undefined,
  .expandble-table .rdt_Table .rdt_TableBody #cell-4-undefined {
    min-width: 83px !important;
    max-width: 82px !important;
  }

  .expandble-table .rdt_Table .rdt_TableBody #cell-2-undefined {
    min-width: 98px !important;
    max-width: 98px !important;
  }

  .expandble-table.ungroup-table-aligned .rdt_Table .rdt_TableBody #cell-3-undefined {
    padding-left: 5px !important;
    min-width: 64px !important;
    max-width: 76px !important;
  }

  .expandble-table.ungroup-table-aligned .rdt_Table .rdt_TableBody #cell-4-undefined {
    padding-left: 13px !important;
    min-width: 72px !important;
    max-width: 65px !important;
  }

  .expandble-table.ungroup-table-aligned .rdt_Table .rdt_TableBody #cell-5-undefined {
    padding-left: 0px !important;
    margin-right: 18px;
  }

  .expandalble-scroll-table.group-row-aligned .rdt_Table .rdt_TableBody #cell-1-undefined {
    min-width: 470px !important;
    max-width: 470px !important;
  }

  .group-row-aligned .rdt_Table .rdt_TableBody #cell-1-undefined {
    margin-left: 22px !important;
  }

  .expandble-table.group-row-aligned .rdt_Table .rdt_TableBody #cell-2-undefined,
  .expandble-table.group-row-aligned .rdt_Table .rdt_TableBody #cell-3-undefined,
  .expandble-table.group-row-aligned .rdt_Table .rdt_TableBody #cell-4-undefined {
    min-width: 80px !important;
    max-width: 80px !important;
  }

  .expandble-table.group-row-aligned .rdt_Table .rdt_TableBody #cell-4-undefined,
  .expandble-table.group-row-aligned .rdt_Table .rdt_TableBody #cell-5-undefined {
    padding-left: 17px !important;
  }

  .left-table .rdt_Table .rdt_TableHead .rdt_TableHeadRow .rdt_TableCol {
    min-width: 40px;
    max-width: 40px;
    padding: 0 5px !important;
    font-size: 11px;
  }

  .left-table .rdt_Table .rdt_TableBody .rdt_TableCell:last-child {
    padding-left: 11px !important;
  }

  .left-table .rdt_Table .rdt_TableBody .rdt_TableCell {
    min-width: 40px;
    max-width: 40px;
    padding: 0 5px !important;
    font-size: 11px;
  }

  .left-table .rdt_Table .rdt_TableBody #cell-7-undefined {
    min-width: 30px !important;
    max-width: 30px !important;
  }

  .left-panel.tokens {
    max-height: 510px;
  }

  .left-table .rdt_Table .rdt_TableHead .rdt_TableHeadRow .rdt_TableCol:first-child,
  .left-table .rdt_Table .rdt_TableBody .rdt_TableCell:first-child {
    min-width: 120px;
    max-width: 65px;
  }

  .left-table .rdt_Table .rdt_TableHead .rdt_TableHeadRow .rdt_TableCol:nth-child(2),
  .left-table .rdt_Table .rdt_TableBody .rdt_TableCell:nth-child(2) {
    min-width: 30px;
    max-width: 30px;
  }

  .custom-padding {
    padding: 0 30px !important;
  }

  .keywords-table.ngram .rdt_Pagination span {
    margin: 0;
  }

  .ngrams-aligned-grouped .rdt_TableBody #cell-1-undefined {
    margin-left: 0px;
    min-width: 85px;
    max-width: 100px;
  }

  .ngrams-aligned-grouped .rdt_TableRow div:nth-child(3) {
    min-width: 23px;
    flex: 0 0 0;
    margin-left: 15px;
  }

  .keywords-table.ngram .rdt_Table .rdt_TableHead .rdt_TableHeadRow div:nth-child(3) {
    margin-left: 15px !important;
  }

  .keywords-table.ngram .rdt_Table .rdt_TableBody #cell-1-undefined {
    padding-left: 8px !important;
    min-width: 91px !important;
    max-width: 91px !important;
  }

  .keywords-table.ngram .rdt_Table .rdt_TableBody #cell-2-undefined {
    padding-left: 8px !important;
  }

  .ngrams-aligned-grouped.expandable-grams-grouping .rdt_Table .rdt_TableBody #cell-1-undefined {
    min-width: 54px !important;
    max-width: 54px !important;
    padding-left: 0px !important;
  }

  .ngrams-aligned-grouped.expandable-grams-grouping .rdt_Table .rdt_TableBody #cell-2-undefined {
    padding-left: 9px !important;
    max-width: 68px !important;
    min-width: 68px !important;
  }

  .ngrams-aligned-grouped.expandable-grams-grouping .rdt_Table .rdt_TableBody #cell-3-undefined {
    padding-left: 16px !important;
    min-width: 60px !important;
    max-width: 60px !important;
  }

  .keywords-table.ngram {
    overflow-x: hidden !important;
  }

  .ngrams-pagination .rdt_Pagination {
    display: flex !important;
    justify-content: flex-start !important;
  }

  .tabs-section .search-page-sec .form-control.w-35 {
    width: 25% !important;
  }

  .basic-single-select {
    width: 170px !important;
  }
}

@media screen and (min-width:1600px) and (max-width:1919px) {
  .tabs-section .search-page-sec {
    left: 72% !important;
    right: 0;
    top: 50px;
  }

  .single-btn.tabs-section .search-page-sec {
    left: 94% !important;
    right: 0;
  }

  .tabs-section .search-page-sec .form-control.w-35 {
    width: 28% !important;
  }

  .single-btn.tabs-section .search-page-sec .form-control.w-35 {
    width: 29% !important;
  }

  /* .keywords-table.ungrouped .rdt_TableHead .rdt_TableHeadRow div:nth-child(2) {
    min-width: 120px !important;
  } */
  .keywords-table.ungrouped #cell-1-undefined {
    max-width: 240px;
    min-width: 240px !important;
    padding-right: 0;
  }

  .keywords-table.blocked-table .rdt_TableHead .rdt_TableHeadRow div:nth-child(2) {
    min-width: 727px !important;
    max-width: 727px;
  }

  /* .keywords-table.blocked-table #cell-1-undefined{
    min-width: 597px !important;
  } */
  .keywords-table.ungrouped .rdt_TableHead .rdt_TableHeadRow div:nth-child(3) {
    min-width: 240px !important;
    max-width: 240px !important;
  }

  .blocked-table.ungrouped .rdt_TableHead .rdt_TableHeadRow div:nth-child(3) {
    max-width: 65px !important;
    min-width: 65px !important;
  }

  .keywords-table.blocked-table .rdt_TableHead .rdt_TableHeadRow div:nth-child(3) {
    max-width: 89px !important;
    min-width: 89px !important;
  }

  .blocked-table.ungrouped #cell-1-undefined {
    min-width: 727px !important;
    max-width: 727px;
  }

  .keywords-table.ungrouped .rdt_TableHead .rdt_TableHeadRow div:nth-child(4) {
    min-width: 330px !important;
    max-width: 330px !important;
  }

  .keywords-table.blocked-table .rdt_TableHead .rdt_TableHeadRow div:nth-child(4) {
    min-width: 80px !important;
    max-width: 80px !important;
  }

  .keywords-table.ungrouped .rdt_TableHead .rdt_TableHeadRow div:nth-child(5) {
    min-width: 80px !important;
    max-width: 80px !important;
  }

  .keywords-table.blocked-table .rdt_TableHead .rdt_TableHeadRow div:nth-child(5) {
    min-width: 82px !important;
    max-width: 82px !important;
  }

  .keywords-table.ungrouped .rdt_TableHead .rdt_TableHeadRow div:nth-child(6),
  .keywords-table.ungrouped .rdt_TableHead .rdt_TableHeadRow div:nth-child(7) {
    min-width: 92px !important;
    max-width: 80px;
  }

  .keywords-table.ungrouped .rdt_TableHead .rdt_TableHeadRow div:nth-child(8) {
    min-width: 80px !important;
    max-width: 80px;
  }

  .keywords-table.ungrouped .rdt_TableHead .rdt_TableHeadRow div:nth-child(9) {
    padding-left: 5px !important;
  }

  .keywords-table.ungrouped #cell-2-undefined {
    min-width: 330px !important;
    max-width: 330px !important;
  }

  .keywords-table.blocked-table #cell-2-undefined {
    min-width: 90px !important;
    max-width: 90px !important;
  }

  .keywords-table.ungrouped #cell-3-undefined,
  .keywords-table.ungrouped #cell-4-undefined {
    min-width: 80px !important;
    max-width: 80px !important;
  }

  .keywords-table.ungrouped #cell-4-undefined {
    min-width: 90px !important;
    max-width: 90px !important;
  }

  .keywords-table.ungrouped #cell-5-undefined {
    min-width: 94px !important;
    max-width: 80px !important;
  }

  .keywords-table.ungrouped #cell-6-undefined {
    min-width: 70px !important;
    max-width: 80px !important;
  }

  .keywords-table.ungrouped #cell-5-undefined {
    padding-left: 18px !important;
  }

  .keywords-table.grouped .rdt_TableHead .rdt_TableHeadRow div:nth-child(2) {
    min-width: 80px !important;
    max-width: 80px !important;
  }

  .keywords-table.grouped .rdt_TableBody #cell-1-undefined {
    min-width: 80px !important;
    max-width: 80px !important;
  }

  .keywords-table.grouped .rdt_TableHead .rdt_TableHeadRow div:nth-child(3),
  .keywords-table.grouped .rdt_TableBody #cell-2-undefined {
    min-width: 140px !important;
    max-width: 140px !important;
  }

  .keywords-table.grouped .rdt_TableHead .rdt_TableHeadRow div:nth-child(4),
  .keywords-table.grouped #cell-3-undefined {
    min-width: 320px !important;
  }

  .keywords-table.grouped-h2 .rdt_TableHead .rdt_TableHeadRow div:nth-child(2) {
    min-width: 80px !important;
    max-width: 80px !important;
  }

  .keywords-table.grouped-h2 .rdt_TableBody #cell-1-undefined {
    min-width: 80px !important;
    max-width: 80px !important;
  }

  .keywords-table.grouped-h2 .rdt_TableHead .rdt_TableHeadRow div:nth-child(3),
  .keywords-table.grouped-h2 .rdt_TableBody #cell-2-undefined {
    min-width: 140px !important;
    max-width: 140px !important;
  }

  .keywords-table.grouped-h2 .rdt_TableHead .rdt_TableHeadRow div:nth-child(4),
  .keywords-table.grouped-h2 #cell-3-undefined {
    min-width: 150px !important;
    max-width: 150px;
  }

  .keywords-table.grouped-h2 .rdt_TableHead .rdt_TableHeadRow div:nth-child(5),
  .keywords-table.grouped-h2 #cell-4-undefined {
    min-width: 140px !important;
    max-width: 140px;
  }

  .keywords-table.ngram .rdt_TableHead .rdt_TableHeadRow div:nth-child(3),
  .keywords-table.ngram .rdt_TableHead .rdt_TableHeadRow div:nth-child(4),
  .keywords-table.ngram .rdt_TableHead .rdt_TableHeadRow div:nth-child(5) {
    min-width: 70px !important;
    max-width: 80px !important;
    padding: 0 !important;
  }

  .keywords-table.ngram .rdt_TableHead .rdt_TableHeadRow div:nth-child(4) {
    padding-left: 30px !important;
  }

  .keywords-table.ngram .rdt_TableHead .rdt_TableHeadRow div:nth-child(5) {
    margin-right: 18px !important;
  }

  .keywords-table.ngram .rdt_TableHead .rdt_TableHeadRow div:nth-child(6) {
    min-width: 30px !important;
    max-width: 40px !important;
    padding: 0 !important;
  }

  .keywords-table.ngram .rdt_TableBody #cell-2-undefined,
  .keywords-table.ngram .rdt_TableBody #cell-3-undefined,
  .keywords-table.ngram .rdt_TableBody #cell-4-undefined {
    min-width: 40px !important;
    max-width: 50px !important;
    padding: 0 !important;
  }

  .keywords-table.ngram .rdt_TableBody #cell-5-undefined {
    min-width: 40px !important;
    max-width: 4px !important;
    padding: 0 !important;
  }

  .projects-details-tab .nav-item {
    width: auto;
    white-space: nowrap;
  }

  .tabs-section {
    margin-left: 20px;
  }

  .file-upload-btn {
    width: 50% !important;
  }

  /* .projects-details-tab .nav-item .nav-link{
    min-width: 120px;
    justify-content: center;
    display: flex;
  } */
  .table-aligned-grouped .rdt_Table .rdt_TableBody .rdt_TableRow #cell-1-undefined {
    min-width: 305px;
    max-width: 600px;
    margin-left: 70px;
  }

  .keywords-table .rdt_TableHead .rdt_TableHeadRow div:nth-child(5),
  .keywords-table .rdt_TableHead .rdt_TableHeadRow div:nth-child(6),
  .keywords-table .rdt_TableHead .rdt_TableHeadRow div:nth-child(7),
  .keywords-table .rdt_TableHead .rdt_TableHeadRow div:nth-child(8),
  .keywords-table .rdt_TableHead .rdt_TableHeadRow div:nth-child(9) {
    padding: 0 !important;
  }


  .table-aligned .rdt_Table .rdt_TableBody .rdt_TableRow #cell-1-undefined {
    min-width: 505px;
    max-width: 472px;
    margin-left: 45px;
  }

  .expand-gh2-tab .rdt_Table .rdt_TableBody .rdt_TableRow #cell-1-undefined {
    min-width: 506px;
  }

  .keywords-table.grouped-aligned .rdt_Table .rdt_TableHeadRow div:nth-child(3) {
    max-width: 450px !important;
    min-width: 450px !important;
  }

  .keywords-table.grouped-aligned .rdt_Table .rdt_TableHeadRow div:nth-child(4),
  /* .keywords-table.grouped-aligned .rdt_Table .rdt_TableHeadRow div:nth-child(5), */
  .keywords-table.grouped-aligned .rdt_Table .rdt_TableHeadRow div:nth-child(6),
  .keywords-table.grouped-aligned .rdt_Table .rdt_TableHeadRow div:nth-child(7),
  .keywords-table.grouped-aligned .rdt_Table .rdt_TableHeadRow div:nth-child(8) {
    min-width: 80px;
    max-width: 80px;
  }

  .keywords-table.grouped-aligned .rdt_Table .rdt_TableHeadRow div:nth-child(5) {
    min-width: 180px;
    max-width: 180px;
  }

  .keywords-table.grouped-table-aligned .rdt_Table .rdt_TableHeadRow div:nth-child(5) {
    min-width: 80px !important;
    max-width: 80px !important;
  }

  .keywords-table.grouped-aligned .rdt_Table .rdt_TableBody #cell-1-undefined {
    max-width: 450px !important;
    min-width: 450px !important;
  }

  .keywords-table.grouped-aligned .rdt_Table .rdt_TableBody #cell-2-undefined,
  .keywords-table.grouped-aligned .rdt_Table .rdt_TableBody #cell-3-undefined,
  .keywords-table.grouped-aligned .rdt_Table .rdt_TableBody #cell-4-undefined,
  .keywords-table.grouped-aligned .rdt_Table .rdt_TableBody #cell-5-undefined,
  .keywords-table.grouped-aligned .rdt_Table .rdt_TableBody #cell-6-undefined {
    min-width: 80px;
    max-width: 80px;
  }

  .keywords-table.grouped-aligned .rdt_Table .rdt_TableBody #cell-3-undefined {
    min-width: 180px;
    max-width: 80px;
  }

  .keywords-table.grouped-table-aligned .rdt_Table .rdt_TableBody #cell-3-undefined {
    min-width: 80px !important;
    max-width: 80px !important;
  }

  .keywords-table.grouped-aligned .expandble-table .rdt_Table .rdt_TableBody #cell-3-undefined {
    min-width: 80px;
    max-width: 80px;
  }

  .expandble-table.table-aligned-grouped .rdt_Table .rdt_TableBody #cell-1-undefined {
    min-width: 652px !important;
    max-width: 652px !important;
  }

  .expandble-table.exp-grouped-table-aligned .rdt_Table .rdt_TableBody #cell-1-undefined {
    min-width: 613px !important;
    max-width: 600px !important;
  }

  /* .expandble-table.grup-row-aligned .rdt_Table .rdt_TableBody #cell-1-undefined{
    min-width: 503px !important;
    max-width: 503px !important;
  } */
  .expandble-table .rdt_Table .rdt_TableBody #cell-2-undefined {
    min-width: 93px !important;
    max-width: 80px !important;
  }

  .expandble-table.ungroup-table-aligned .rdt_Table .rdt_TableBody #cell-2-undefined {
    min-width: 95px !important;
    max-width: 80px !important;
  }

  .expandble-table .rdt_Table .rdt_TableBody #cell-3-undefined {
    min-width: 78px !important;
    max-width: 80px !important;
  }

  .expandble-table .rdt_Table .rdt_TableBody #cell-4-undefined {
    min-width: 80px !important;
    max-width: 80px !important;
  }

  .expandble-table .rdt_Table .rdt_TableBody #cell-4-undefined {
    margin-left: 15px !important;
  }

  .expandble-table.ungroup-table-aligned .rdt_Table .rdt_TableBody #cell-4-undefined {
    margin-left: 0px !important;
    min-width: 77px !important;
    max-width: 78px !important;
  }

  .expandalble-scroll-table .rdt_Table .rdt_TableBody #cell-1-undefined {
    min-width: 473px !important;
    max-width: 473px !important;
  }

  .copy-icon {
    position: absolute;
    right: 0;
  }

  .group-row-aligned .rdt_Table .rdt_TableBody #cell-1-undefined {
    margin-left: 22px !important;
  }

  .expandble-table.group-row-aligned .rdt_Table .rdt_TableBody #cell-2-undefined,
  .expandble-table.group-row-aligned .rdt_Table .rdt_TableBody #cell-3-undefined,
  .expandble-table.group-row-aligned .rdt_Table .rdt_TableBody #cell-4-undefined {
    min-width: 80px !important;
    max-width: 80px !important;
  }

  .expandble-table.group-row-aligned .rdt_Table .rdt_TableBody #cell-4-undefined {
    margin-left: 0 !important;
    padding-left: 15px !important;
  }

  .res-width {
    width: 160px;
  }

  .para-check p {
    max-width: 120px;
  }

  .left-table .rdt_Table .rdt_TableHead .rdt_TableHeadRow .rdt_TableCol {
    min-width: 45px;
    max-width: 50px;
    padding: 0 5px !important;
    font-size: 11px;
  }

  .left-table .rdt_Table .rdt_TableBody .rdt_TableCell {
    min-width: 45px;
    max-width: 50px;
    padding: 0 5px !important;
    font-size: 11px;
  }

  .left-panel.tokens {
    max-height: 515px;
  }

  .left-table .rdt_Table .rdt_TableHead .rdt_TableHeadRow .rdt_TableCol:first-child,
  .left-table .rdt_Table .rdt_TableBody .rdt_TableCell:first-child {
    min-width: 100px;
    max-width: 60px;
  }

  .left-table .rdt_Table .rdt_TableHead .rdt_TableHeadRow .rdt_TableCol:nth-child(2),
  .left-table .rdt_Table .rdt_TableBody .rdt_TableCell:nth-child(2) {
    min-width: 30px;
    max-width: 30px;
  }

  .custom-padding {
    padding: 0 30px !important;
  }

  .ngrams-aligned-grouped .rdt_TableBody #cell-1-undefined {
    margin-left: 0;
    min-width: 100px;
    max-width: 100px;
  }

  .ngrams-aligned-grouped .rdt_TableRow div:nth-child(3) {
    min-width: 23px;
    flex: 0 0 0;
    margin-left: 0px;
  }

  .keywords-table.ngram .rdt_TableHead .rdt_TableHeadRow div:nth-child(3) {
    margin-left: 23px;
  }

  .ngrams-pagination .rdt_Pagination {
    position: relative !important;
    left: 2px;
  }

  .ngrams-pagination .rdt_Pagination #pagination-first-page,
  .ngrams-pagination .rdt_Pagination #pagination-previous-page,
  .ngrams-pagination .rdt_Pagination #pagination-next-page,
  .ngrams-pagination .rdt_Pagination #pagination-last-page {
    width: 18px;
  }

  .keywords-table.ngram .rdt_Table .rdt_TableHead .rdt_TableHeadRow div:nth-child(3) {
    margin-left: 15px !important;
  }

  .keywords-table.ngram .rdt_Table .rdt_TableBody #cell-1-undefined {
    padding-left: 8px !important;
  }

  .keywords-table.ngram .rdt_Table .rdt_TableBody #cell-2-undefined {
    padding-left: 8px !important;
  }

  .ngrams-aligned-grouped.expandable-grams-grouping .rdt_Table .rdt_TableBody #cell-1-undefined {
    padding-left: 0px !important;
    min-width: 56px !important;
    min-width: 56px !important;
  }

  .ngrams-aligned-grouped.expandable-grams-grouping .rdt_Table .rdt_TableBody #cell-2-undefined {
    padding-left: 7px !important;
    max-width: 93px !important;
    min-width: 93px !important;
  }

  .ngrams-aligned-grouped.expandable-grams-grouping .rdt_Table .rdt_TableBody #cell-3-undefined {
    padding-left: 13px !important;
    min-width: 56px !important;
    max-width: 56px !important;
  }

  .ngram .rdt_Table .rdt_TableHeadRow div:nth-child(3) div:first-child div {
    display: -webkit-inline-box !important;
    white-space: break-spaces !important;
  }

  .ngram .rdt_Table .rdt_TableHeadRow div:nth-child(3) div:first-child span {
    position: absolute;
    right: -14px;
    top: 7px;
  }

  .tabs-section .search-page-sec .form-control.w-35 {
    width: 23% !important;
  }

  .basic-single-select {
    width: 170px !important;
  }

  .single-btn .basic-single-select {
    width: 200px !important;
  }

  .single-btn .group-btn {
    height: 100px;
    width: 80px;
    min-width: 100px;
  }
}
@media screen and (min-width:1820px) and (max-width:1860px) {
  .expand-gh2-tab .rdt_Table .rdt_TableBody .rdt_TableRow #cell-1-undefined {
    min-width: 521px;
}
}
@media screen and (min-width:1880px) and (max-width:1900px) {
  .ngrams-aligned-grouped.expandable-grams-grouping .rdt_Table .rdt_TableBody #cell-1-undefined {
    padding-left: 0px !important;
    min-width: 56px !important;
    max-width: 56px !important;
  }

  .ngrams-aligned-grouped.expandable-grams-grouping .rdt_Table .rdt_TableBody #cell-2-undefined {
    min-width: 91px !important;
    max-width: 91px !important;
  }

  .expand-gh2-tab .rdt_Table .rdt_TableBody .rdt_TableRow #cell-1-undefined {
    min-width: 526px;
  }

  .keywords-table.ngram .rdt_Table .rdt_TableHead .rdt_TableHeadRow div:nth-child(4) {
    margin-left: 23px !important;
    padding-left: 18px !important;
  }

  .keywords-table.ngram .rdt_Table .rdt_TableBody #cell-3-undefined {
    padding-left: 3px !important;
  }

  .ngrams-aligned-grouped.expandable-grams-grouping .rdt_Table .rdt_TableBody #cell-3-undefined {
    padding-left: 16px !important;
    min-width: 60px !important;
    max-width: 60px !important;
  }

  .ngrams-aligned-grouped .rdt_TableBody #cell-1-undefined {
    margin-left: 0px;
    min-width: 100px;
    max-width: 100px;
  }

  .ngrams-aligned-grouped .rdt_TableRow div:nth-child(3) {
    min-width: 23px;
    flex: 0 0 0;
    margin-left: 0px;
  }

  .keywords-table.ngram .rdt_TableHead .rdt_TableHeadRow div:nth-child(3) {
    margin-left: 23px;
  }

  .keywords-table.ngram .rdt_TableHead .rdt_TableHeadRow div:nth-child(3),
  .keywords-table.ngram .rdt_TableHead .rdt_TableHeadRow div:nth-child(4),
  .keywords-table.ngram .rdt_TableHead .rdt_TableHeadRow div:nth-child(5) {
    min-width: 60px !important;
    max-width: 90px !important;
    padding: 0 !important;
  }

  .keywords-table.ngram .rdt_TableHead .rdt_TableHeadRow div:nth-child(5) {
    margin-right: 18px !important;
  }

  .ngram .rdt_Table .rdt_TableHeadRow div:nth-child(3) div:first-child span {
    position: absolute;
    right: 7px;
    top: 7px;
  }
}

@media screen and (min-width:1300px) and (max-width:1600px) {
  .left-panel.tokens {
    max-height: 500px;
  }
}

@media screen and (min-width:1441px) and (max-width:1599px) {
  .tabs-section .search-page-sec {
    top: 50px;
    left: 50px;
    right: 0;
  }

  .left-panel.tokens {
    max-height: 500px;
  }



  .tabs-section .search-page-sec .col-1.ms-2 {
    padding-left: 5px;
    margin-left: 0 !important;
  }

  .tabs-section .search-page-sec .form-control {
    width: 45px !important;
    height: 25px;
    margin-left: 40px;
  }

  .tab-content .rdt_Pagination {
    font-size: 9px;
    justify-content: flex-start;
  }

  .tab-content .rdt_Pagination .eVjHRC {
    margin: 0 2px;
  }

  .w-180-laptop {
    width: 180px !important;
  }

  .w-140-laptop {
    width: 140px !important;
  }

  .keywords-table.ungrouped #cell-1-undefined {
    min-width: 410px !important;
    max-width: 410px;
  }

  .keywords-table.ungrouped .rdt_TableHead .rdt_TableHeadRow div:nth-child(3) {
    min-width: 410px !important;
    max-width: 410px !important;
  }

  .keywords-table.ungrouped .rdt_TableHead .rdt_TableHeadRow div:nth-child(4) {
    min-width: 300px !important;
    max-width: 300px !important;
  }

  .keywords-table.ungrouped .rdt_TableHead .rdt_TableHeadRow div:nth-child(5),
  .keywords-table.ungrouped .rdt_TableHead .rdt_TableHeadRow div:nth-child(6),
  .keywords-table.ungrouped .rdt_TableHead .rdt_TableHeadRow div:nth-child(7) {
    min-width: 80px !important;
    max-width: 80px !important;
  }

  .keywords-table.ungrouped #cell-2-undefined {
    min-width: 300px !important;
    max-width: 300px !important;
  }

  .keywords-table.ungrouped #cell-3-undefined,
  .keywords-table.ungrouped #cell-4-undefined,
  .keywords-table.ungrouped #cell-5-undefined,
  .keywords-table.ungrouped #cell-6-undefined {
    min-width: 80px !important;
    max-width: 80px !important;
  }

  .keywords-table.ngram .rdt_TableHead .rdt_TableHeadRow div:nth-child(3),
  .keywords-table.ngram .rdt_TableHead .rdt_TableHeadRow div:nth-child(4),
  .keywords-table.ngram .rdt_TableHead .rdt_TableHeadRow div:nth-child(5) {
    min-width: 60px !important;
    max-width: 90px !important;
    padding: 0 !important;
  }

  .keywords-table.ngram .rdt_TableHead .rdt_TableHeadRow div:nth-child(6) {
    min-width: 30px !important;
    max-width: 40px !important;
    padding: 0 !important;
  }

  .keywords-table.ngram .rdt_TableBody #cell-2-undefined,
  .keywords-table.ngram .rdt_TableBody #cell-3-undefined,
  .keywords-table.ngram .rdt_TableBody #cell-4-undefined {
    min-width: 60px !important;
    max-width: 90px !important;
    padding: 0 !important;
  }

  .keywords-table.ngram .rdt_TableBody #cell-5-undefined {
    min-width: 40px !important;
    max-width: 4px !important;
    padding: 0 !important;
  }

  .projects-details-tab .nav-item .nav-link {
    padding: 0 5px;
  }

  .projects-details-tab .nav-item {
    width: auto;
    white-space: nowrap;
  }

  .keywords-table.grouped-h2 .rdt_Table .rdt_TableHeadRow div:nth-child(2) {
    max-width: 80px;
    min-width: 80px;
  }

  .keywords-table.grouped-h2 .rdt_Table .rdt_TableHeadRow div:nth-child(5) {
    max-width: 220px;
    min-width: 150px;
  }

  .keywords-table.grouped-h2 .rdt_Table .rdt_TableHeadRow div:nth-child(6),
  .keywords-table.grouped-h2 .rdt_Table .rdt_TableHeadRow div:nth-child(7),
  .keywords-table.grouped-h2 .rdt_Table .rdt_TableHeadRow div:nth-child(8) {
    padding: 0;
    min-width: 10px;
    max-width: 70px;
  }

  .keywords-table.grouped-h2 .rdt_Table .rdt_TableBody #cell-1-undefined {
    max-width: 80px;
    min-width: 80px;
  }

  .keywords-table.grouped-h2 .rdt_Table .rdt_TableBody #cell-4-undefined {
    max-width: 150px;
    min-width: 150px;
  }

  .keywords-table.grouped-h2 .rdt_Table .rdt_TableBody #cell-5-undefined,
  .keywords-table.grouped-h2 .rdt_Table .rdt_TableBody #cell-6-undefined,
  .keywords-table.grouped-h2 .rdt_Table .rdt_TableBody #cell-7-undefined {
    min-width: 10px;
    max-width: 70px;
    padding: 0;
  }

  .keywords-table.grouped-aligned .rdt_Table .rdt_TableHeadRow div:nth-child(4),
  .keywords-table.grouped-aligned .rdt_Table .rdt_TableHeadRow div:nth-child(5),
  .keywords-table.grouped-aligned .rdt_Table .rdt_TableHeadRow div:nth-child(6) {
    min-width: 10px;
    max-width: 40px;
  }

  .keywords-table.grouped-aligned .rdt_Table .rdt_TableBody #cell-4-undefined,
  .keywords-table.grouped-aligned .rdt_Table .rdt_TableBody #cell-5-undefined,
  .keywords-table.grouped-aligned .rdt_Table .rdt_TableBody #cell-6-undefined {
    min-width: 10px;
    max-width: 40px;
  }

  .file-upload-btn {
    width: 100% !important;
  }

  .table-aligned-grouped .rdt_Table .rdt_TableBody .rdt_TableRow #cell-1-undefined {
    min-width: 305px;
    max-width: 600px;
    margin-left: 93px;
  }

  .table-aligned .rdt_Table .rdt_TableBody .rdt_TableRow #cell-1-undefined {
    min-width: 505px;
    max-width: 600px;
    margin-left: 45px;
  }

  .keywords-table.grouped-aligned .rdt_Table .rdt_TableHeadRow div:nth-child(3) {
    max-width: 630px !important;
    min-width: 630px !important;
  }

  .keywords-table.grouped-table-aligned .rdt_Table .rdt_TableHeadRow div:nth-child(3) {
    max-width: 287px !important;
    min-width: 287px !important;
  }

  .keywords-table.grouped-aligned .rdt_Table .rdt_TableHeadRow div:nth-child(4),
  .keywords-table.grouped-aligned .rdt_Table .rdt_TableHeadRow div:nth-child(5),
  .keywords-table.grouped-aligned .rdt_Table .rdt_TableHeadRow div:nth-child(6),
  .keywords-table.grouped-aligned .rdt_Table .rdt_TableHeadRow div:nth-child(7),
  .keywords-table.grouped-aligned .rdt_Table .rdt_TableHeadRow div:nth-child(8) {
    min-width: 80px;
    max-width: 80px;
  }

  .keywords-table.grouped-aligned .rdt_Table .rdt_TableBody #cell-1-undefined {
    max-width: 630px !important;
    min-width: 630px !important;
  }

  .keywords-table.grouped-table-aligned .rdt_Table .rdt_TableBody #cell-1-undefined {
    max-width: 287px !important;
    min-width: 287px !important;
  }

  .keywords-table.grouped-aligned .rdt_Table .rdt_TableBody #cell-2-undefined,
  .keywords-table.grouped-aligned .rdt_Table .rdt_TableBody #cell-3-undefined,
  .keywords-table.grouped-aligned .rdt_Table .rdt_TableBody #cell-4-undefined,
  .keywords-table.grouped-aligned .rdt_Table .rdt_TableBody #cell-5-undefined,
  .keywords-table.grouped-aligned .rdt_Table .rdt_TableBody #cell-6-undefined {
    min-width: 80px;
    max-width: 80px;
  }

  .expandble-table.table-aligned-grouped .rdt_Table .rdt_TableBody #cell-1-undefined {
    min-width: 792px !important;
    max-width: 792px !important;
  }

  .expandble-table.exp-grouped-table-aligned .rdt_Table .rdt_TableBody #cell-1-undefined {
    min-width: 450px !important;
    max-width: 450px !important;
  }

  .expandble-table .rdt_Table .rdt_TableBody #cell-2-undefined,
  .expandble-table .rdt_Table .rdt_TableBody #cell-3-undefined,
  .expandble-table .rdt_Table .rdt_TableBody #cell-4-undefined {
    min-width: 80px !important;
    max-width: 80px !important;
  }

  .expandalble-scroll-table .rdt_Table .rdt_TableBody #cell-1-undefined {
    min-width: 470px !important;
    max-width: 470px !important;
  }

  .copy-icon {
    position: absolute;
    right: 0;
  }

  .blocked-table.ungrouped .rdt_TableHead .rdt_TableHeadRow div:nth-child(3) {
    max-width: 65px !important;
    min-width: 65px !important;
  }

  .blocked-table.ungrouped.keywords-table .rdt_TableHead .rdt_TableHeadRow div:nth-child(2) {
    max-width: 420px !important;
    min-width: 420px !important;
  }

  .blocked-table.ungrouped .rdt_TableHead .rdt_TableHeadRow div:nth-child(4),
  .blocked-table.ungrouped .rdt_TableHead .rdt_TableHeadRow div:nth-child(5) {
    max-width: 65px !important;
    min-width: 65px !important;
  }

  .keywords-table.blocked-table .rdt_Table .rdt_TableBody #cell-1-undefined {
    max-width: 420px !important;
    min-width: 420px !important;
  }

  .blocked-table.keywords-table.ungrouped #cell-2-undefined,
  .blocked-table.keywords-table.ungrouped #cell-3-undefined,
  .blocked-table.keywords-table.ungrouped #cell-4-undefined {
    max-width: 65px !important;
    min-width: 65px !important;
  }

  .group-row-aligned .rdt_Table .rdt_TableBody #cell-1-undefined {
    margin-left: 22px !important;
  }

  .expandble-table.group-row-aligned .rdt_Table .rdt_TableBody #cell-2-undefined,
  .expandble-table.group-row-aligned .rdt_Table .rdt_TableBody #cell-3-undefined,
  .expandble-table.group-row-aligned .rdt_Table .rdt_TableBody #cell-4-undefined {
    min-width: 80px !important;
    max-width: 80px !important;
  }

  .res-width {
    width: 130px;
  }

  .para-check p {
    max-width: 100px;
  }

  .left-table .rdt_Table .rdt_TableHead .rdt_TableHeadRow .rdt_TableCol {
    min-width: 55px;
    max-width: 55px;
    padding: 0 5px !important;
    font-size: 9px;
  }

  .left-table .rdt_Table .rdt_TableBody .rdt_TableCell {
    min-width: 55px;
    max-width: 55px;
    padding: 0 5px !important;
    font-size: 9px;
  }

  .left-table .rdt_Table .rdt_TableHead .rdt_TableHeadRow .rdt_TableCol:first-child,
  .left-table .rdt_Table .rdt_TableBody .rdt_TableCell:first-child {
    min-width: 40px;
    max-width: 40px;
  }

  .left-table .rdt_Table .rdt_TableHead .rdt_TableHeadRow .rdt_TableCol:nth-child(2),
  .left-table .rdt_Table .rdt_TableBody .rdt_TableCell:nth-child(2) {
    min-width: 30px;
    max-width: 30px;
  }

  .custom-padding {
    padding: 0 30px !important;
  }

  .ngrams-aligned-grouped .rdt_TableBody #cell-1-undefined {
    margin-left: 57px;
    min-width: 100px;
    max-width: 100px;
  }

  .ngrams-aligned-grouped .rdt_TableRow div:nth-child(3) {
    min-width: 23px;
    flex: 0 0 0;
    margin-left: 0px;
  }

  .single-btn .basic-single-select {
    width: 200px !important;
  }

  .single-btn .group-btn {
    width: 80px;
    min-width: 80px;
  }
}

@media screen and (min-width: 1300px) and (max-width:1440px) {
  .tabs-section .search-page-sec {
    height: 30px;
    padding-top: 3px;
  }

  .single-btn.tabs-section .search-page-sec .form-control {
    width: 100px !important;
    height: 25px;
    margin-left: 40px;
  }

  .tabs-section .search-page-sec .col-1.ms-2 {
    padding-left: 5px;
    margin-left: 0 !important;
  }

  .tabs-section .search-page-sec .form-control {
    width: 45px !important;
    height: 25px;
    margin-left: 40px;
  }

  .tab-content .rdt_Pagination {
    font-size: 9px;
    justify-content: flex-start;
  }

  .tab-content .rdt_Pagination .eVjHRC {
    margin: 0 5px;
  }

  .projects-details-tab .nav-item .nav-link {
    padding: 0 5px;
    font-size: 10px;
  }

  /* .keywords-table.ungrouped .rdt_TableHead .rdt_TableHeadRow div:nth-child(2) {
    min-width: 120px !important;
    max-width: 380px;
  } */
  .keywords-table.ungrouped .rdt_Table .rdt_TableBody #cell-1-undefined {
    min-width: 270px !important;
    max-width: 270px;
  }

  .keywords-table.ungrouped .rdt_TableHead .rdt_TableHeadRow div:nth-child(3) {
    min-width: 270px !important;
    max-width: 270px !important;
  }

  .keywords-table.ungrouped .rdt_TableHead .rdt_TableHeadRow div:nth-child(4) {
    min-width: 300px !important;
    max-width: 300px !important;
  }

  .keywords-table.blocked-table .rdt_TableHead .rdt_TableHeadRow div:nth-child(4) {
    min-width: 60px !important;
    max-width: 78px !important;
  }

  .keywords-table.ungrouped .rdt_TableHead .rdt_TableHeadRow div:nth-child(5),
  .keywords-table.ungrouped .rdt_TableHead .rdt_TableHeadRow div:nth-child(6),
  .keywords-table.ungrouped .rdt_TableHead .rdt_TableHeadRow div:nth-child(7) {
    min-width: 80px !important;
    max-width: 80px !important;
  }

  .keywords-table.ungrouped .rdt_TableHead .rdt_TableHeadRow div:nth-child(8) {
    min-width: 70px !important;
    max-width: 80px !important;
  }

  .keywords-table.ungrouped .rdt_TableHead .rdt_TableHeadRow div:nth-child(9) {
    padding-left: 8px !important;
  }

  .keywords-table.ungrouped #cell-2-undefined {
    min-width: 300px !important;
    max-width: 300px !important;
  }

  .keywords-table.blocked-table #cell-2-undefined {
    min-width: 64px !important;
    max-width: 64px !important;
  }

  .keywords-table.ungrouped #cell-3-undefined,
  .keywords-table.ungrouped #cell-4-undefined,
  .keywords-table.ungrouped #cell-5-undefined,
  .keywords-table.ungrouped #cell-6-undefined,
  .keywords-table.ungrouped #cell-7-undefined {
    min-width: 80px !important;
    max-width: 80px !important;
  }

  .keywords-table.ungrouped #cell-7-undefined {
    padding-left: 0 !important;
  }

  .expandble-table.ungroup-table-aligned #cell-5-undefined {
    padding-left: 0 !important;
  }

  /* .keywords-table.ungrouped #cell-5-undefined {
    padding-left: 30px !important;95px
  } */
  .keywords-table.ngram .rdt_TableHead .rdt_TableHeadRow div:nth-child(3) .keywords-table.ngram .rdt_TableHead .rdt_TableHeadRow div:nth-child(5) {
    min-width: 60px !important;
    max-width: 90px !important;
    padding: 0 !important;
    margin-left: 24px;
  }

  .keywords-table.ngram .rdt_TableHead .rdt_TableHeadRow div:nth-child(4) {
    min-width: 70px !important;
    max-width: 47px !important;
    margin-left: -10px;
  }

  .keywords-table.ngram .rdt_TableHead .rdt_TableHeadRow div:nth-child(6) {
    min-width: 30px !important;
    max-width: 40px !important;
    padding: 0 !important;
  }

  .keywords-table.ngram .rdt_TableBody #cell-3-undefined,
  .keywords-table.ngram .rdt_TableBody #cell-4-undefined {
    min-width: 60px !important;
    max-width: 90px !important;
    padding: 0 15px 0 0;
  }

  .keywords-table.ngram .rdt_TableBody #cell-2-undefined {
    min-width: 70px !important;
    max-width: 90px !important;
    padding: 0 15px 0 0 !important;
  }

  .keywords-table.ngram .rdt_TableBody #cell-5-undefined {
    min-width: 40px !important;
    max-width: 4px !important;
    padding: 0 !important;
  }

  .projects-details-tab .nav-item {
    width: auto;
    white-space: nowrap;
  }

  .keywords-table.grouped-h2 .rdt_Table .rdt_TableHeadRow div:nth-child(2) {
    max-width: 80px;
    min-width: 80px;
  }

  .keywords-table.grouped-h2 .rdt_Table .rdt_TableHeadRow div:nth-child(5) {
    max-width: 220px;
    min-width: 150px;
  }

  .keywords-table.grouped-h2 .rdt_Table .rdt_TableHeadRow div:nth-child(6),
  .keywords-table.grouped-h2 .rdt_Table .rdt_TableHeadRow div:nth-child(7),
  .keywords-table.grouped-h2 .rdt_Table .rdt_TableHeadRow div:nth-child(8) {
    padding: 0;
    min-width: 50px;
    max-width: 70px;
  }

  .keywords-table.grouped-h2 .rdt_Table .rdt_TableBody #cell-1-undefined {
    max-width: 80px;
    min-width: 80px;
  }

  .keywords-table.grouped-h2 .rdt_Table .rdt_TableBody #cell-4-undefined {
    max-width: 150px;
    min-width: 150px;
  }

  .keywords-table.grouped-h2 .rdt_Table .rdt_TableBody #cell-5-undefined,
  .keywords-table.grouped-h2 .rdt_Table .rdt_TableBody #cell-6-undefined,
  .keywords-table.grouped-h2 .rdt_Table .rdt_TableBody #cell-7-undefined {
    min-width: 10px;
    max-width: 70px;
    padding: 0;
  }

  .col-4.ms-1 {
    margin-left: 20px !important;
  }

  .file-upload-btn {
    width: 100% !important;
  }

  .table-aligned-grouped .rdt_Table .rdt_TableBody .rdt_TableRow #cell-1-undefined {
    min-width: 305px;
    max-width: 600px;
    margin-left: 70px;
  }

  .table-aligned .rdt_Table .rdt_TableBody .rdt_TableRow #cell-1-undefined {
    min-width: 505px;
    max-width: 600px;
    margin-left: 45px;
  }

  .keywords-table.grouped-aligned .rdt_Table .rdt_TableHeadRow div:nth-child(3) {
    max-width: 488px !important;
    min-width: 488px !important;
  }

  .keywords-table.grouped-table-aligned .rdt_Table .rdt_TableHeadRow div:nth-child(3) {
    max-width: 225px !important;
    min-width: 300px !important;
  }

  .blocked-table.keywords-table.ungrouped .rdt_Table .rdt_TableBody #cell-1-undefined {
    min-width: 520px !important;
    max-width: 520px !important;
  }

  .blocked-table.keywords-table.ungrouped .rdt_Table .rdt_TableHeadRow div:nth-child(2) {
    min-width: 520px;
    max-width: 520px;

  }

  .keywords-table.grouped-aligned .rdt_Table .rdt_TableHeadRow div:nth-child(4),
  .keywords-table.grouped-aligned .rdt_Table .rdt_TableHeadRow div:nth-child(5),
  .keywords-table.grouped-aligned .rdt_Table .rdt_TableHeadRow div:nth-child(6),
  .keywords-table.grouped-aligned .rdt_Table .rdt_TableHeadRow div:nth-child(7),
  .keywords-table.grouped-aligned .rdt_Table .rdt_TableHeadRow div:nth-child(8) {
    min-width: 80px;
    max-width: 80px;
  }

  .keywords-table.grouped-aligned .rdt_Table .rdt_TableBody #cell-1-undefined {
    max-width: 488px !important;
    min-width: 488px !important;
    white-space: normal !important;
    text-overflow: normal !important;
    overflow: visible !important;
  }

  .keywords-table.grouped-table-aligned .rdt_Table .rdt_TableBody #cell-1-undefined {
    max-width: 225px !important;
    min-width: 300px !important;
    white-space: normal !important;
    text-overflow: normal !important;
    overflow: visible !important;
  }

  .keywords-table.grouped-aligned .rdt_Table .rdt_TableBody #cell-2-undefined,
  .keywords-table.grouped-aligned .rdt_Table .rdt_TableBody #cell-3-undefined,
  .keywords-table.grouped-aligned .rdt_Table .rdt_TableBody #cell-4-undefined,
  .keywords-table.grouped-aligned .rdt_Table .rdt_TableBody #cell-5-undefined,
  .keywords-table.grouped-aligned .rdt_Table .rdt_TableBody #cell-6-undefined {
    min-width: 80px;
    max-width: 80px;
  }

  .expandble-table.table-aligned-grouped .rdt_Table .rdt_TableBody #cell-1-undefined {
    min-width: 650px !important;
    max-width: 650px !important;
  }

  .expandble-table.exp-grouped-table-aligned .rdt_Table .rdt_TableBody #cell-1-undefined {
    min-width: 463px !important;
    max-width: 420px !important;
  }

  .expandble-table.exp-grouped-table-aligned .rdt_Table .rdt_TableBody #cell-5-undefined {
    padding-left: 14px !important;
  }

  .expandble-table .rdt_Table .rdt_TableBody #cell-2-undefined,
  .expandble-table .rdt_Table .rdt_TableBody #cell-3-undefined {
    min-width: 70px !important;
    max-width: 80px !important;
  }

  .expandble-table .rdt_Table .rdt_TableBody #cell-4-undefined {
    min-width: 95px !important;
    max-width: 80px !important;
  }

  .expandble-table.ungroup-table-aligned .rdt_Table .rdt_TableBody #cell-3-undefined {
    padding-left: 26px;
  }

  .expandble-table .rdt_Table .rdt_TableBody #cell-4-undefined {
    margin-left: 4px !important;
  }

  .exp-grouped-table-aligned .rdt_Table .rdt_TableBody #cell-4-undefined {
    margin-left: 0px !important;
  }

  .expandalble-scroll-table .rdt_Table .rdt_TableBody #cell-1-undefined {
    min-width: 470px !important;
    max-width: 470px !important;
  }

  .copy-icon {
    position: absolute;
    right: 0;
  }

  .blocked-table.ungrouped #cell-1-undefined {
    max-width: 548px;
  }

  .blocked-table.ungrouped .rdt_TableHead .rdt_TableHeadRow div:nth-child(3) {
    max-width: 65px !important;
    min-width: 65px !important;
  }

  .group-row-aligned .rdt_Table .rdt_TableBody #cell-1-undefined {
    margin-left: 22px !important;
  }

  .expandble-table.group-row-aligned .rdt_Table .rdt_TableBody #cell-2-undefined,
  .expandble-table.group-row-aligned .rdt_Table .rdt_TableBody #cell-3-undefined,
  .expandble-table.group-row-aligned .rdt_Table .rdt_TableBody #cell-4-undefined {
    min-width: 80px !important;
    max-width: 80px !important;
  }

  .res-width {
    width: 100px;
  }

  .para-check p {
    max-width: 80px;
  }

  .expandble-table.exp-grouped-table-aligned .rdt_Table .rdt_TableBody #cell-2-undefined,
  .expandble-table.exp-grouped-table-aligned .rdt_Table .rdt_TableBody #cell-3-undefined,
  .expandble-table.exp-grouped-table-aligned .rdt_Table .rdt_TableBody #cell-4-undefined {
    min-width: 70px !important;
    max-width: 80px !important;
  }

  .expandble-table.exp-grouped-table-aligned .rdt_Table .rdt_TableBody #cell-4-undefined {
    padding-left: 15px !important;
  }

  .custom-padding {
    padding: 0px 30px !important;
  }

  .left-table .rdt_Table .rdt_TableHead .rdt_TableHeadRow .rdt_TableCol {
    min-width: 37px;
    max-width: 37px;
    padding: 0 5px !important;
    font-size: 11px;
    margin-right: -8px !important;
  }

  .left-table .rdt_Table .rdt_TableBody .rdt_TableCell {
    min-width: 37px;
    max-width: 37px;
    padding: 0 5px !important;
    font-size: 11px;
    margin-right: -8px !important;
  }

  .left-table .rdt_Table .rdt_TableHead .rdt_TableHeadRow .rdt_TableCol:first-child,
  .left-table .rdt_Table .rdt_TableBody .rdt_TableCell:first-child {
    min-width: 95px;
    max-width: 95px;
  }

  .left-table .rdt_Table .rdt_TableHead .rdt_TableHeadRow .rdt_TableCol:nth-child(2),
  .left-table .rdt_Table .rdt_TableBody .rdt_TableCell:nth-child(2) {
    min-width: 30px;
    max-width: 30px;
  }

  .custom-padding {
    padding: 0 30px !important;
  }

  .ngrams-aligned-grouped .rdt_TableBody #cell-1-undefined {
    margin-left: 25px;
    min-width: 87px;
    max-width: 100px;
  }

  .ngrams-aligned-grouped .rdt_TableRow div:nth-child(3) {
    min-width: 23px;
    flex: 0 0 0;
    margin-left: 0px;
  }

  .keywords-table.ngram .rdt_Table .rdt_TableHead .rdt_TableHeadRow div:nth-child(3) {
    margin-left: 8px !important;
  }

  .keywords-table.ngram .rdt_Table .rdt_TableHead .rdt_TableHeadRow div:nth-child(5) {
    padding-left: 9px !important;
  }

  .keywords-table.ngram .rdt_Table .rdt_TableBody #cell-2-undefined {
    padding-left: 6px !important;
  }

  .ngrams-aligned-grouped.expandable-grams-grouping .rdt_Table .rdt_TableBody #cell-1-undefined {
    padding-left: 13px !important;
  }

  .ngrams-aligned-grouped.expandable-grams-grouping .rdt_Table .rdt_TableBody #cell-2-undefined {
    padding-left: 4px !important;
    min-width: 67px !important;
    max-width: 67px !important;
  }

  .ngrams-pagination .rdt_Pagination {
    font-size: 9px !important;
  }

  .ngrams-pagination .rdt_Pagination #pagination-first-page,
  .ngrams-pagination .rdt_Pagination #pagination-previous-page,
  .ngrams-pagination .rdt_Pagination #pagination-next-page,
  .ngrams-pagination .rdt_Pagination #pagination-last-page {
    width: 21px;
    position: relative;
    right: 22px;
  }

  .tabs-section .search-page-sec .form-control.w-35 {
    width: 20% !important;
    margin-left: 21px;
    font-size: 9px;
  }

  .basic-single-select {
    width: 120px !important;
    font-size: 9px;
    padding-right: 0 !important;
    padding-left: 4px;
  }

  .single-btn .search-page-sec .form-control.w-35 {
    width: 32% !important;
    padding-top: 10px !important;
    font-size: 12px;
    margin-left: 25px;
  }

  .single-btn .basic-single-select {
    width: 170px !important;
    font-size: 12px;
  }

  .single-btn .group-btn {
    min-width: 80px;
    width: 80px;
  }
}

@media screen and (min-width: 1400px) and (max-width: 1440px) {
  .ngrams-aligned-grouped .rdt_TableBody #cell-1-undefined {
    margin-left: 25px;
    min-width: 99px;
    max-width: 100px;
  }

  .ngrams-aligned-grouped .rdt_TableBody #cell-3-undefined {
    padding-left: 1px !important;
  }

  .ngrams-pagination .rdt_Pagination #pagination-first-page,
  .ngrams-pagination .rdt_Pagination #pagination-previous-page,
  .ngrams-pagination .rdt_Pagination #pagination-next-page,
  .ngrams-pagination .rdt_Pagination #pagination-last-page {
    width: 17px;
    position: relative;
    right: 20px;
  }

  .ngrams-aligned-grouped.expandable-grams-grouping .rdt_Table .rdt_TableBody #cell-1-undefined {
    padding-left: 0 !important;
  }

  .ngrams-aligned-grouped .rdt_TableBody #cell-1-undefined {
    margin-left: 0px;
    min-width: 67px;
    max-width: 67px;
  }

  .ngrams-aligned-grouped.expandable-grams-grouping .rdt_Table .rdt_TableBody #cell-2-undefined {
    min-width: 93px !important;
    max-width: 93px !important;
  }

  .ngrams-aligned-grouped.expandable-grams-grouping .rdt_Table .rdt_TableBody #cell-3-undefined {
    min-width: 64px !important;
    max-width: 64px !important;
  }
}

@media screen and (min-width:1200px) and(max-width:1299px) {
  .custom-padding {
    padding: 0 30px !important;
  }

  .tabs-section .search-page-sec {
    top: 59px;
    left: auto;
  }

  .blocked-unblocked-tab .nav-item .nav-link {
    width: 115px !important;
    font-size: 13px;
    padding: 0;
  }

  .tab-content .rdt_Pagination {
    font-size: 9px;
    justify-content: flex-start;
  }

  .col-4.ms-1 {
    margin-left: 20px !important;
  }

  .file-upload-btn {
    width: 100% !important;
  }

  .table-aligned-grouped .rdt_Table .rdt_TableBody .rdt_TableRow #cell-1-undefined {
    min-width: 305px;
    max-width: 600px;
    margin-left: 45px;
  }

  .table-aligned .rdt_Table .rdt_TableBody .rdt_TableRow #cell-1-undefined {
    min-width: 505px;
    max-width: 600px;
    margin-left: 45px;
  }

  .copy-icon {
    position: absolute;
    right: 0;
  }

  .blocked-table.ungrouped #cell-1-undefined {
    max-width: 548px;
  }

  .blocked-table.ungrouped .rdt_TableHead .rdt_TableHeadRow div:nth-child(3) {
    max-width: 65px !important;
    min-width: 65px !important;
  }

  .blocked-table.keywords-table.ungrouped .rdt_Table .rdt_TableBody #cell-1-undefined {
    min-width: 480px !important;
    max-width: 480px !important;
  }

  .blocked-table.keywords-table.ungrouped .rdt_Table .rdt_TableHeadRow div:nth-child(2) {
    min-width: 479px;
    max-width: 350px;

  }
}

@media screen and (min-width: 1024px) and (max-width:1200px) {
  .table-aligned-grouped .rdt_Table .rdt_TableBody .rdt_TableRow #cell-1-undefined {
    min-width: 305px;
    max-width: 600px;
    margin-left: 45px;
  }

  .table-aligned .rdt_Table .rdt_TableBody .rdt_TableRow #cell-1-undefined {
    min-width: 505px;
    max-width: 600px;
    margin-left: 45px;
  }

  .copy-icon {
    position: absolute;
    right: 0;
  }

  .group-row-aligned .rdt_Table .rdt_TableBody #cell-1-undefined {
    margin-left: 22px !important;
  }

  .expandble-table.group-row-aligned .rdt_Table .rdt_TableBody #cell-2-undefined,
  .expandble-table.group-row-aligned .rdt_Table .rdt_TableBody #cell-3-undefined,
  .expandble-table.group-row-aligned .rdt_Table .rdt_TableBody #cell-4-undefined {
    min-width: 80px !important;
    max-width: 80px !important;
  }
}

@media screen and (min-width: 768px) and (max-width:1024px) {
  .table-aligned-grouped .rdt_Table .rdt_TableBody .rdt_TableRow #cell-1-undefined {
    min-width: 305px;
    max-width: 600px;
    margin-left: 45px;
  }

  .table-aligned .rdt_Table .rdt_TableBody .rdt_TableRow #cell-1-undefined {
    min-width: 402px;
    max-width: 600px;
    margin-left: 45px;
  }

  .copy-icon {
    position: absolute;
    right: 0;
  }
}

@media screen and (min-width: 1600px) and (max-width:1820px) {
  .custom-padding {
    padding: 0px 40px !important;
  }

  .left-table .rdt_Table .rdt_TableHead .rdt_TableHeadRow .rdt_TableCol {
    min-width: 35px;
    max-width: 35px;
    padding: 0 5px !important;
    font-size: 11px;
  }

  .left-table .rdt_Table .rdt_TableBody .rdt_TableCell {
    min-width: 35px;
    max-width: 35px;
    padding: 0 5px !important;
    font-size: 11px;
  }

  .left-table .rdt_Table .rdt_TableHead .rdt_TableHeadRow .rdt_TableCol:first-child,
  .left-table .rdt_Table .rdt_TableBody .rdt_TableCell:first-child {
    min-width: 90px;
    max-width: 40px;
  }

  .left-table .rdt_Table .rdt_TableHead .rdt_TableHeadRow .rdt_TableCol:nth-child(2),
  .left-table .rdt_Table .rdt_TableBody .rdt_TableCell:nth-child(2) {
    min-width: 30px;
    max-width: 30px;
  }

  .custom-padding {
    padding: 0 30px !important;
  }

  .tabs-section .search-page-sec .form-control.w-35 {
    width: 19% !important;
  }

  .basic-single-select {
    width: 160px !important;
  }
}

@media screen and (min-width: 1320px) and (max-width:1415px) {

  .left-table .rdt_Table .rdt_TableHead .rdt_TableHeadRow .rdt_TableCol:first-child,
  .left-table .rdt_Table .rdt_TableBody .rdt_TableCell:first-child {
    min-width: 65px;
    max-width: 75px;
  }

  .ngrams-aligned-grouped .rdt_TableRow div:nth-child(1) {
    min-width: 70px;
  }

  .keywords-table.ngram .rdt_TableBody #cell-3-undefined {
    margin-left: 25px;
  }

  .keywords-table.ngram .rdt_TableHead .rdt_TableHeadRow div:nth-child(3) {
    min-width: 102px !important;
    max-width: 100px !important;
  }

  .ngrams-aligned-grouped .rdt_TableRow div:nth-child(3) {
    margin-left: 12px;
  }

  .keywords-table.ngram .rdt_TableBody #cell-2-undefined {
    min-width: 45px !important;
    max-width: 40px !important;
  }

  .keywords-table.ngram .rdt_TableHead .rdt_TableHeadRow div:nth-child(4) {
    min-width: 70px !important;
    max-width: 47px !important;
    margin-left: -12px;
  }

  .keywords-table.ngram .rdt_Table .rdt_TableHead .rdt_TableHeadRow div:nth-child(3) {
    margin-left: 8px !important;
  }

  .keywords-table.ngram .rdt_Table .rdt_TableHead .rdt_TableHeadRow div:nth-child(5) {
    padding-left: 9px !important;
  }

  .keywords-table.ngram .rdt_Table .rdt_TableBody #cell-2-undefined {
    padding-left: 6px !important;
  }

  .ngrams-aligned-grouped.expandable-grams-grouping .rdt_Table .rdt_TableBody #cell-1-undefined {
    padding-left: 0 !important;
  }

  .ngrams-aligned-grouped.expandable-grams-grouping .rdt_Table .rdt_TableBody #cell-2-undefined {
    padding-left: 4px !important;
    min-width: 68px !important;
    max-width: 68px !important;
  }

  .ngrams-aligned-grouped.expandable-grams-grouping .rdt_Table .rdt_TableBody #cell-3-undefined {
    min-width: 64px !important;
    max-width: 64px !important;
  }

  .ngrams-aligned-grouped .rdt_TableBody #cell-1-undefined {
    margin-left: 0;
    min-width: 67px !important;
    max-width: 67px !important;
  }
}
